import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let departments = {
    labels: {
      header: "Departments",
      saveBtn: "Save",
      archived: "Archived",
    },
    notification: {
      assignDept: {
        success: "Departments edit has been successful!",
        error: "Unable to edit departments",
      },
    },
    errorAlert: {
      message: "Unable to load departments",
    },
    modal: {
      message: {
        top: "You have unsaved changes!",
        bottom: "Are you sure you want to quit the editor?",
      },
      button: {
        label: "Quit",
      },
    },
  };

  if (isPolish) {
    departments = {
      labels: {
        header: "Oddziały",
        saveBtn: "Zapisz",
        archived: "Archiwalne",
      },
      notification: {
        assignDept: {
          success: "Edycja oddziałów przebiegła pomyślnie!",
          error: "Błąd edycji oddziałów",
        },
      },
      errorAlert: {
        message: "Nie można załadować oodziałów",
      },
      modal: {
        message: {
          top: "Masz niezapisane zmiany!",
          bottom: "Czy na pewno chcesz wyjść z edytora?",
        },
        button: {
          label: "Wyjdź",
        },
      },
    };
  }

  return { departments };
};
