import { useContext } from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import QuestionnairesContext from "contexts/questionnaire-context/QuestionnairesContext";
import { QUESTION_TYPE_IDS, Answers, CountryCodes } from "types/types";
import { NumberOfPhotos, PhotoPreview } from "../types/tableOfContents.types";

import Thumb from "components/atoms/Thumb";

const Container = styled.div<{ isAnswered: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  font-size: 1.2rem;
  background-color: ${({ isAnswered, theme }) =>
    isAnswered ? theme.primary_50 : "none"};

  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon-yes {
    height: 2rem;

    path {
      fill: ${({ theme }) => theme.yesButton};
    }
  }

  .icon-no {
    height: 2rem;

    path {
      fill: ${({ theme }) => theme.noButton};
    }
  }
`;

const AnswerValue = styled.div`
  ${({ theme }) => theme.ellipsisMultiline};
  -webkit-line-clamp: 1;
  max-width: 100%;

  .numeric {
    font-size: 1.8rem;
  }

  .select {
    font-size: 1.4rem;
  }

  .file-answer,
  .yes-no-photo-answer {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
    padding-right: 1rem;
    padding-left: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .file-answer {
    padding-bottom: 0.35rem;
  }

  .label {
    font-size: 1.4rem;
  }

  .number-of-images {
    font-family: GothamBold;
  }

  .image-thumb-container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .image {
    height: 3rem;
    border-radius: 0.2rem;
  }

  .align {
    transform: translateY(0.2rem);
  }

  .no-photos {
    line-height: 4rem;
    text-align: left;
    font-style: italic;
    font-size: 1.3rem;
    opacity: 0.8;
  }
`;

const NoAnswer = styled.div`
  font-style: italic;
  opacity: 0.6;
  font-size: 1.3rem;
`;

const NaLabel = styled.span`
  font-size: 1.3rem;
  font-family: GothamBold;
`;

const { YES_NO_PHOTO, TEXT, FILE_PHOTO, NUMERIC, SELECT } = QUESTION_TYPE_IDS;
const { YES, NO } = Answers;

const ThumbIcon = ({ up = false, down = false }: { up?: boolean; down?: boolean }) => {
  const customClass = up ? "icon-yes" : "icon-no";

  return <Thumb up={up} down={down} filled customClass={customClass} />;
};

type AnswerProps = {
  questionId: string;
  numberOfPhotos: NumberOfPhotos;
  photoPreview: PhotoPreview;
};

const getTranslations = (language: string) =>
  language.includes(CountryCodes.PL) ? "Nie Dotyczy" : "Not Applicable";

function Answer({ questionId, numberOfPhotos, photoPreview }: AnswerProps) {
  const {
    state: { answers },
  } = useContext(QuestionnairesContext);
  const {
    t,
    i18n: { language },
  } = useTranslation("table-of-contents");
  const naLabel = getTranslations(language);

  let answerValue = null;

  const answer = answers.find((answer) => answer.Question === questionId);

  if (answer) {
    const { QuestionType, Value } = answer;

    switch (QuestionType) {
      case TEXT:
        answerValue = Value;
        break;
      case NUMERIC:
        answerValue = <span className='numeric'>{Value}</span>;
        break;
      case SELECT:
        answerValue = <span className='select'>{Value}</span>;
        break;
      default:
        answerValue = <NaLabel>{naLabel}</NaLabel>;
        if (Value === YES) answerValue = <ThumbIcon up />;
        if (Value === NO) answerValue = <ThumbIcon down />;
    }

    if (QuestionType === YES_NO_PHOTO) {
      if (Value === YES) {
        answerValue = <ThumbIcon up />;
      }

      if (Value === NO) {
        if (!numberOfPhotos) {
          answerValue = (
            <div className='yes-no-photo-answer'>
              <div className='no-photos'>{t("no-photos")}</div>
              <ThumbIcon down />
            </div>
          );
        } else {
          answerValue = (
            <div className='yes-no-photo-answer'>
              <div className='label'>
                <span>{t("number-of-photos")}&nbsp;</span>
                <span className='number-of-images'>{numberOfPhotos}</span>
              </div>

              <div className='image-thumb-container'>
                <img src={photoPreview} className='image small' alt='' />

                <ThumbIcon down />
              </div>
            </div>
          );
        }
      }
    }

    if (QuestionType === FILE_PHOTO) {
      if (numberOfPhotos) {
        answerValue = (
          <div className='file-answer'>
            <div className='label'>
              <span>{t("number-of-photos")}&nbsp;</span>
              <span className='number-of-images'>{numberOfPhotos}</span>
            </div>
            <img src={photoPreview} className='image align' alt='' />
          </div>
        );
      } else {
        answerValue = <div className='no-photos'>{t("no-photos")}</div>;
      }
    }
  }

  return (
    <Container isAnswered={!!answer}>
      {!!answer ? (
        <AnswerValue>{answerValue}</AnswerValue>
      ) : (
        <NoAnswer>{t("no-answer")}</NoAnswer>
      )}
    </Container>
  );
}

export default Answer;
