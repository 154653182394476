import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import { isMobile } from "utils/utils";
import useModal from "hooks/useModal";
import useAnimate from "hooks/useAnimate";
import useClickOutside from "hooks/useClickOutside";

import { ReactComponent as MoreIcon } from "assets/icons/more-vertical.svg";
import ConfirmationModal from "components/organisms/ConfirmationModal";

const Container = styled.div<{ isMobile: boolean; animate: boolean; duration: string }>`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  border-radius: 0.4rem;
  padding: 1.5rem;

  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}

  .more-icon {
    width: 2.4rem;
    position: absolute;
    right: 0;
    top: 1rem;
    fill: ${({ theme }) => theme.primary};
    cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

    @media screen and (min-height: 616px) {
      width: 3rem;
    }
  }
`;

export const Label = styled.div<{ marginRight?: boolean }>`
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 0.6rem;
  margin-right: ${({ marginRight }) => (marginRight ? "1rem" : "0")};
`;

export const Value = styled.div<{ isMobile: boolean }>`
  font-size: 1.6rem;
  padding-right: 3.5rem;
  ${({ theme }) => theme.ellipsisMultiline}
  -webkit-line-clamp: 6;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  @media screen and (min-height: 616px) {
    font-size: 1.8rem;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 1.1rem;
  right: 2.4rem;
  width: 70%;
  background-color: ${({ theme }) => theme.primary};
  ${({ theme }) => theme.cardShadow};
  border-radius: 0.4rem;
  z-index: ${({ theme }) => theme.level10};
`;

const Option = styled.div`
  color: ${({ theme }) => theme.aliceBlue};
  padding: 1.5rem;
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.primary_800};
    border-radius: 0.4rem;
  }
`;

type ReactionInAnswerProps = {
  value: string;
  onEditIconClick: () => void;
  removeReactionComment: () => void;
};

function ReactionInAnswer({
  value,
  onEditIconClick,
  removeReactionComment,
}: ReactionInAnswerProps) {
  const [menuVisible, setMenuVisible] = useState(false);
  const { open, openModal, closeModal } = useModal();
  const { animate, duration } = useAnimate();
  const { t } = useTranslation(["questionnaires", "common"]);

  const ref = useRef(null);

  // --------------- Menu handlers ---------------

  const openMenu = () => {
    setMenuVisible(true);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  // --------------- On click handlers ---------------

  const onRemoveButtonClick = () => {
    removeReactionComment();
    closeModal();
  };

  const onMoreIconClick = () => {
    if (!menuVisible) {
      openMenu();
    }
  };

  const onEditClick = () => {
    closeMenu();
    onEditIconClick();
  };

  const onDeleteClick = () => {
    closeMenu();
    openModal();
  };

  const handleClickOutside = () => {
    closeMenu();
  };

  useClickOutside(ref, handleClickOutside);

  return !!value ? (
    <Container isMobile={isMobile} animate={animate} duration={`${duration}ms`}>
      <Value isMobile={isMobile} onClick={onEditClick}>
        {value}
      </Value>
      <MoreIcon className='more-icon' onClick={onMoreIconClick} />

      {menuVisible && (
        <Menu ref={ref}>
          <Option onClick={onEditClick}>{t("common:menu.edit")}</Option>
          <Option onClick={onDeleteClick}>{t("common:menu.delete")}</Option>
        </Menu>
      )}

      <ConfirmationModal
        message={t("modal.question.remove-description")}
        onClick={onRemoveButtonClick}
        onClose={closeModal}
        open={open}
        buttonLabel={t("common:button.delete")}
      />
    </Container>
  ) : null;
}

export default ReactionInAnswer;
