import styled from "styled-components/macro";

const Container = styled.ul``;

const Placeholder = styled.li`
  height: 3.5rem;
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;
  background-color: rgba(61, 91, 148, 0.5);

  &:nth-child(2) {
    background-color: rgba(43, 64, 114, 0.5);
  }
`;
const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect};
  opacity: 0.25;
`;
function Placeholders() {
  return (
    <Container>
      {[1, 2].map((item) => (
        <Placeholder key={item}>
          <Shimmer />
        </Placeholder>
      ))}
    </Container>
  );
}

export default Placeholders;
