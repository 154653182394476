import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let error = {
    number_too_large: "Number too large!",
  };

  let modal = {
    message: "Are you sure you want to delete the answer?",
    btnLabel: "Delete",
  };

  if (isPolish) {
    error = {
      number_too_large: "Za duża wartość!",
    };

    modal = {
      message: "Czy na pewno chcesz skasować odpowiedź?",
      btnLabel: "Skasuj",
    };
  }

  return { error, modal };
};
