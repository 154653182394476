import { TaskPermissionCollections } from "types/tasks.types";
import { Permission, ActionsEnum, PermFields } from "types/permissions.types";
import { PERM_OBJECT_INIT } from "./utils/permissions.utils";

type TasksPermissionsObject = Record<TaskPermissionCollections, Permission[]>;

const {
  ID,
  USER_CREATED,
  USER_UPDATED,
  DATE_CREATED,
  DATE_UPDATED,
  ASSIGN_TO,
  DESCRIPTION,
  SORT,
  STATUS,
  TITLE,
  FILES,
  RELATED,
  DEADLINE,
} = PermFields;

const { TASKS, TASKS_FILES, TASKS_RELATED } = TaskPermissionCollections;
const { CREATE, DELETE, READ, SHARE, UPDATE } = ActionsEnum;

const tasksPermissionsInitial: TasksPermissionsObject = {
  [TASKS]: [],
  [TASKS_FILES]: [],
  [TASKS_RELATED]: [],
};

function useTasksPermissions(userPermissions: Permission[]) {
  const filteredPerms = userPermissions.filter(({ collection }) =>
    collection.includes(TASKS),
  );

  const tasksPermissions = filteredPerms.reduce(
    (acc: TasksPermissionsObject, curr: Permission) => {
      acc = {
        ...acc,
        [curr.collection]: [
          ...acc[curr.collection as unknown as TaskPermissionCollections],
          curr,
        ],
      };

      return acc;
    },
    tasksPermissionsInitial,
  );

  const createPermissionObject = (
    collection: TaskPermissionCollections,
    actionPayload: ActionsEnum,
  ) => {
    const filtered = tasksPermissions[collection].filter(
      ({ action }) => action === actionPayload,
    );

    if (filtered.length) {
      return filtered[0].fields.reduce((acc, curr) => {
        if (curr === "*") {
          acc = { ALL: true };
        } else {
          acc = {
            ...acc,
            [curr]: true,
          };
        }

        return acc;
      }, PERM_OBJECT_INIT);
    }
  };

  // --------------- Permission objects ---------------

  const tasksPerms = {
    [CREATE]: createPermissionObject(TASKS, CREATE),
    [READ]: createPermissionObject(TASKS, READ),
    [UPDATE]: createPermissionObject(TASKS, UPDATE),
    [DELETE]: createPermissionObject(TASKS, DELETE),
    [SHARE]: createPermissionObject(TASKS, SHARE),
  };

  const { create, read, update, share, delete: Delete } = tasksPerms;

  // Create
  const PERMITTED_TO_CREATE = {
    ALL: !!create?.ALL,
    [ID]: !!create?.id,
    [USER_CREATED]: !!create?.user_created,
    [USER_UPDATED]: !!create?.user_updated,
    [DATE_CREATED]: !!create?.date_created,
    [DATE_UPDATED]: !!create?.date_updated,
    [ASSIGN_TO]: !!create?.AssignTo,
    [DESCRIPTION]: !!create?.Description,
    [SORT]: !!create?.sort,
    [STATUS]: !!create?.status,
    [TITLE]: !!create?.Title,
    [FILES]: !!create?.Files,
    [RELATED]: !!create?.Related,
    [DEADLINE]: !!create?.Deadline,
  };

  // Read
  const PERMITTED_TO_READ = {
    ALL: !!read?.ALL,
    [ID]: !!read?.id,
    [USER_CREATED]: !!read?.user_created,
    [USER_UPDATED]: !!read?.user_updated,
    [DATE_CREATED]: !!read?.date_created,
    [DATE_UPDATED]: !!read?.date_updated,
    [ASSIGN_TO]: !!read?.AssignTo,
    [DESCRIPTION]: !!read?.Description,
    [SORT]: !!read?.sort,
    [STATUS]: !!read?.status,
    [TITLE]: !!read?.Title,
    [FILES]: !!read?.Files,
    [RELATED]: !!read?.Related,
    [DEADLINE]: !!read?.Deadline,
  };

  // Update
  const PERMITTED_TO_UPDATE = {
    ALL: !!update?.ALL,
    [ID]: !!update?.id,
    [USER_CREATED]: !!update?.user_created,
    [USER_UPDATED]: !!update?.user_updated,
    [DATE_CREATED]: !!update?.date_created,
    [DATE_UPDATED]: !!update?.date_updated,
    [ASSIGN_TO]: !!update?.AssignTo,
    [DESCRIPTION]: !!update?.Description,
    [SORT]: !!update?.sort,
    [STATUS]: !!update?.status,
    [TITLE]: !!update?.Title,
    [FILES]: !!update?.Files,
    [RELATED]: !!update?.Related,
    [DEADLINE]: !!update?.Deadline,
  };

  // Delete
  const PERMITTED_TO_DELETE = {
    ALL: !!Delete?.ALL,
    [ID]: !!Delete?.id,
    [USER_CREATED]: !!Delete?.user_created,
    [USER_UPDATED]: !!Delete?.user_updated,
    [DATE_CREATED]: !!Delete?.date_created,
    [DATE_UPDATED]: !!Delete?.date_updated,
    [ASSIGN_TO]: !!Delete?.AssignTo,
    [DESCRIPTION]: !!Delete?.Description,
    [SORT]: !!Delete?.sort,
    [STATUS]: !!Delete?.status,
    [TITLE]: !!Delete?.Title,
    [FILES]: !!Delete?.Files,
    [RELATED]: !!Delete?.Related,
    [DEADLINE]: !!Delete?.Deadline,
  };

  // Share
  const PERMITTED_TO_SHARE = {
    ALL: !!share?.ALL,
    [ID]: !!share?.id,
    [USER_CREATED]: !!share?.user_created,
    [USER_UPDATED]: !!share?.user_updated,
    [DATE_CREATED]: !!share?.date_created,
    [DATE_UPDATED]: !!share?.date_updated,
    [ASSIGN_TO]: !!share?.AssignTo,
    [DESCRIPTION]: !!share?.Description,
    [SORT]: !!share?.sort,
    [STATUS]: !!share?.status,
    [TITLE]: !!share?.Title,
    [FILES]: !!share?.Files,
    [RELATED]: !!share?.Related,
    [DEADLINE]: !!share?.Deadline,
  };

  const TASKS_PERMISSIONS = {
    PERMITTED_TO_CREATE,
    PERMITTED_TO_READ,
    PERMITTED_TO_UPDATE,
    PERMITTED_TO_DELETE,
    PERMITTED_TO_SHARE,
  };

  return {
    TASKS_PERMISSIONS,
  };
}

export default useTasksPermissions;
