import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { QUESTION_TYPE_IDS, FormTypes, Answers } from "types/types";
import { PromptProps, PromptType } from "./types/prompt.types";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDevices from "hooks/useDevices";
import { getPromptTranslations } from "./translations/prompt.translations";

import { ReactComponent as PhotoIcon } from "assets/icons/photo-camera-2.svg";

import PromptContainer from "./components/prompt-container/PromptContainer";

import { IconContainer, Label } from "./styles/prompt.styles";
import useAnimate from "hooks/useAnimate";

const { FILE_PHOTO, YES_NO_PHOTO } = QUESTION_TYPE_IDS;
const { QUESTIONNAIRE, ACCIDENT } = FormTypes;
const { NO } = Answers;

function PhotoPrompt({
  onClick,
  questionType,
  currentQuestionId,
  answerToCurrentQuestion,
}: PromptProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { animate, duration } = useAnimate();

  const { pathname } = useLocation();

  const formType = pathname.includes("/questionnaire") ? QUESTIONNAIRE : ACCIDENT;
  const { checkIfContainsPhotos } = usePhotosObjectStore(formType);
  const { isVideoInput } = useDevices();

  const isPhotoType = questionType === FILE_PHOTO || questionType === YES_NO_PHOTO;

  const {
    i18n: { language },
  } = useTranslation();
  const label = getPromptTranslations(PromptType.PHOTO, language);

  useEffect(() => {
    if (!currentQuestionId || !isVideoInput) return;

    if (
      !isPhotoType ||
      (questionType === YES_NO_PHOTO && answerToCurrentQuestion?.Value !== NO)
    ) {
      setIsVisible(false);
      return;
    }

    checkIfContainsPhotos(currentQuestionId).then((containtPhotos) => {
      setIsVisible(!containtPhotos);
    });
  }, [
    checkIfContainsPhotos,
    currentQuestionId,
    isPhotoType,
    answerToCurrentQuestion,
    questionType,
    isVideoInput,
  ]);

  return isVisible ? (
    <PromptContainer onClick={onClick} animate={animate} duration={duration}>
      <IconContainer>
        <PhotoIcon className='icon' />
      </IconContainer>
      <Label>{label}</Label>
    </PromptContainer>
  ) : null;
}

export default PhotoPrompt;
