import styled, { css } from "styled-components/macro";

import useQuestionnaire from "hooks/useQuestionnaire";
import useAnimate from "hooks/useAnimate";

import { Question, SubText } from "../styles/questionnaireStyles";

const Container = styled.div<{ animate: boolean; duration: string }>`
  padding-top: 1rem;
  margin-bottom: 2rem;
  user-select: none;

  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

function QuestionTitle() {
  const { animate, duration } = useAnimate();
  const { currentQuestion } = useQuestionnaire();

  const question = currentQuestion?.QuestionText as string;
  const subtext = currentQuestion?.QuestionSubtext as string;

  return (
    <Container animate={animate} duration={`${duration}ms`}>
      <Question>{question}</Question>
      {!!subtext && <SubText dangerouslySetInnerHTML={{ __html: subtext }} />}
    </Container>
  );
}

export default QuestionTitle;
