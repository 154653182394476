import styled from "styled-components/macro";

const Container = styled.ul``;

const Placeholder = styled.li`
  height: 7rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.listItemColor};
`;
const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;

function Placeholders() {
  return (
    <Container>
      {[1, 2].map((item) => (
        <Placeholder key={item}>
          <Shimmer />
        </Placeholder>
      ))}
    </Container>
  );
}

export default Placeholders;
