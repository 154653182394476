import Select from "react-select";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";

import useAnimate from "hooks/useAnimate";
import { isMobile } from "utils/utils";
import { CountryCodes } from "types/types";

const Container = styled.div<{ animate: boolean; duration: string }>`
  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

const NoOptions = styled.div`
  font-style: italic;
  text-align: center;
  margin-top: 2rem;
`;

type AnswerSelectOption = {
  label: string;
  value: string;
};

type QuestionSelectProps = {
  options: string | null;
  onAnswerSelectChange: (value: string) => void;
  answerSelectValue: { label: string; value: string };
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    noOptions: "No options provided...",
    placeholder: "Select answer",
  };

  if (isPolish) {
    labels = {
      noOptions: "Brak opcji wyboru...",
      placeholder: "Wybierz odpowiedź",
    };
  }

  return { labels };
};

function AnswerSelect({
  options,
  onAnswerSelectChange,
  answerSelectValue,
}: QuestionSelectProps) {
  const { animate, duration } = useAnimate();
  const {
    i18n: { language },
  } = useTranslation();
  const { labels } = getTranslations(language);
  let content = null;

  const onChange = (option: AnswerSelectOption | null) => {
    const value = option?.value ?? "";

    onAnswerSelectChange(value);
  };

  if (!options) {
    content = <NoOptions>{labels.noOptions}</NoOptions>;
  }

  if (options) {
    const selectOptions: AnswerSelectOption[] = options
      .split("\n")
      .map((option) => ({ label: option, value: option }))
      .filter(({ value }) => !!value.trim()) // Removes empty options
      .map(({ label, value }) => ({ label: label.trim(), value: value.trim() })); // Trims options that contain spaces

    content = (
      <Select
        onChange={onChange}
        options={selectOptions}
        value={answerSelectValue.value ? answerSelectValue : null} // Setting value like this shows placeholder.
        placeholder={labels.placeholder}
        isClearable
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            cursor: !isMobile ? "pointer" : "none",
          }),
          singleValue: (styles, params) => ({
            ...styles,
            color: "#212f5a",
          }),
        }}
      />
    );
  }

  return (
    <Container animate={animate} duration={`${duration}ms`}>
      {content}
    </Container>
  );
}

export default AnswerSelect;
