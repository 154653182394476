import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { isAfter } from "date-fns";

import { formatDate } from "utils/utils";
import { getTranslations } from "../../../../../../translations/tasks.translations";

const Container = styled.div`
  font-size: 1.3rem;
`;

const Line = styled.div`
  &:not(:last-child) {
    margin-bottom: 0.3rem;
  }
`;

const Label = styled.span`
  &::after {
    content: " ";
  }
`;

const Value = styled.span<{ isOverdue?: boolean }>`
  ${({ isOverdue }) =>
    isOverdue &&
    css`
      color: ${({ theme }) => theme.errorColor};
    `};
`;

type TaskDatesProps = {
  date_created: number | Date;
  Deadline: string | null;
};

function TaskDates({ date_created, Deadline }: TaskDatesProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const { labels } = getTranslations(language);

  const isOverdue = isAfter(new Date(), new Date(Deadline as string));

  return (
    <Container>
      <Line>
        <Label>{labels.dateCreated}:</Label>
        <Value>{formatDate(date_created)}</Value>
      </Line>
      {!!Deadline && (
        <Line>
          <Label>{labels.deadline}:</Label>
          <Value isOverdue={isOverdue}>{formatDate(Deadline)}</Value>
        </Line>
      )}
    </Container>
  );
}

export default TaskDates;
