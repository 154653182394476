import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useTimeout from "./useTimeout";

function useAnimate() {
  const [animate, setAnimate] = useState(false);
  const params = useParams();
  const setTimeOut = useTimeout();
  const duration = 300;

  useEffect(() => {
    setAnimate(true);
  }, [params]);

  useEffect(() => {
    if (animate) {
      setTimeOut(() => {
        setAnimate(false);
      }, duration);
    }
  }, [animate, setTimeOut]);

  return { animate, duration };
}

export default useAnimate;
