import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { CountryCodes } from "types/types";

import Thumb from "components/atoms/Thumb";
import AnimatedArrow from "./components/animated-arrow/AnimatedArrow";

const Container = styled.div`
  position: relative;

  .chevron-right {
    width: 3rem;
    position: absolute;
    top: 50%;
    right: 0.2rem;
    transform: translateY(-50%);
    animation: move-chevron forwards 1.8s ease-in-out infinite;

    path {
      stroke: ${({ theme }) => theme.white};
    }

    @keyframes move-chevron {
      0% {
        right: 1.5rem;
      }
    }
  }
`;

const ThumbContainer = styled.div<{ na: boolean }>`
  ${({ na }) =>
    !na &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    `}
`;

const Label = styled.span<{ smallFont: boolean }>`
  font-size: 1.8rem;

  ${({ smallFont }) =>
    smallFont &&
    css`
      line-height: 1.25;
      display: inline-block;
      font-size: 1.3rem;

      @media screen and (min-width: 360px) {
        font-size: 1.4rem;
      }
    `}
`;

type ButtonLabelProps = {
  selected: boolean;
  up?: boolean;
  down?: boolean;
  na?: boolean;
};

const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    yes: "Yes",
    no: "No",
    notApplicable: "Not applicable",
  };

  if (isPolish) {
    labels = {
      yes: "Tak",
      no: "Nie",
      notApplicable: "Nie dotyczy",
    };
  }

  return { labels };
};

function ButtonLabel({
  selected,
  up = false,
  down = false,
  na = false,
}: ButtonLabelProps) {
  const {
    i18n: { language },
  } = useTranslation("common");
  const {
    labels: { yes, no, notApplicable },
  } = getTranslations(language);

  let label = "";

  if (up) label = yes;
  if (down) label = no;
  if (na) label = notApplicable;

  return (
    <Container>
      <ThumbContainer na={na}>
        <Thumb up={up} down={down} filled={selected} />
        <Label smallFont={na}>
          {label.split(" ").map((word, i) => (
            <div key={`${word}-${i}`}>{word}</div>
          ))}
        </Label>
      </ThumbContainer>

      {selected && <AnimatedArrow />}
    </Container>
  );
}

export default ButtonLabel;
