import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

import { getTranslations } from "../../translations/profile.translations";

import { ReactComponent as WarningIcon } from "assets/icons/warning-colored.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Top = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.8rem;

  @media screen and (min-width: 340px) {
    font-size: 2rem;
  }

  .warn-icon {
    width: 3rem;
    height: 3rem;
  }
`;
const Bottom = styled.span`
  padding-left: 2.5rem;
  font-size: 1.6rem;

  @media screen and (min-width: 340px) {
    font-size: 1.8rem;
  }
`;

function ModalMessage() {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    departments: { modal },
  } = getTranslations(language);

  return (
    <Container>
      <Top>
        <WarningIcon className='warn-icon' />
        <span>{modal.message.top}</span>
      </Top>
      <Bottom>{modal.message.bottom}</Bottom>
    </Container>
  );
}

export default ModalMessage;
