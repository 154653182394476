import {
  ActionsEnum,
  CollectionEnum,
  PermFields,
  Permission,
} from "types/permissions.types";
import { createPermissionObject, filterByCollection } from "./utils/permissions.utils";

const {
  ID,
  STATUS,
  USER_CREATED,
  USER_UPDATED,
  DATE_CREATED,
  DATE_UPDATED,
  SERIAL_NUMBER,
  TAGS,
  DEPARTMENT,
  LAST_ASSESSMENT,
  SERVICE_EXPIRY_DATE,
  MISC,
  ASSESSMENTS,
  PROCESS_NAME,
  PROCESS_TYPE,
  ADDRESS,
  GEOLOCATION,
} = PermFields;

const { PROCESSES } = CollectionEnum;
const { CREATE, DELETE, READ, SHARE, UPDATE } = ActionsEnum;

function useProcessesPermissions(userPermissions: Permission[]) {
  const permissionsArray = filterByCollection(userPermissions, PROCESSES);

  const permsGroupedByAction = {
    [CREATE]: createPermissionObject(permissionsArray, CREATE),
    [READ]: createPermissionObject(permissionsArray, READ),
    [UPDATE]: createPermissionObject(permissionsArray, UPDATE),
    [DELETE]: createPermissionObject(permissionsArray, DELETE),
    [SHARE]: createPermissionObject(permissionsArray, SHARE),
  };

  const { create, read, update, share, delete: Delete } = permsGroupedByAction;

  const PERMITTED_TO_CREATE = {
    ALL: !!create?.ALL,
    [ID]: !!create?.id,
    [STATUS]: !!create?.status,
    [USER_CREATED]: !!create?.user_created,
    [USER_UPDATED]: !!create?.user_updated,
    [DATE_CREATED]: !!create?.date_created,
    [DATE_UPDATED]: !!create?.date_updated,
    [SERIAL_NUMBER]: !!create?.SerialNo,
    [TAGS]: !!create?.Tags,
    [DEPARTMENT]: !!create?.Department,
    [LAST_ASSESSMENT]: !!create?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!create?.ServiceExpiryDate,
    [MISC]: !!create?.Misc,
    [ASSESSMENTS]: !!create?.Assessments,
    [PROCESS_NAME]: !!create?.ProcessName,
    [PROCESS_TYPE]: !!create?.ProcessType,
    [ADDRESS]: !!create?.Address,
    [GEOLOCATION]: !!create?.Geolocation,
  };

  const PERMITTED_TO_READ = {
    ALL: !!read?.ALL,
    [ID]: !!read?.id,
    [STATUS]: !!read?.status,
    [USER_CREATED]: !!read?.user_created,
    [USER_UPDATED]: !!read?.user_updated,
    [DATE_CREATED]: !!read?.date_created,
    [DATE_UPDATED]: !!read?.date_updated,
    [SERIAL_NUMBER]: !!read?.SerialNo,
    [TAGS]: !!read?.Tags,
    [DEPARTMENT]: !!read?.Department,
    [LAST_ASSESSMENT]: !!read?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!read?.ServiceExpiryDate,
    [MISC]: !!read?.Misc,
    [ASSESSMENTS]: !!read?.Assessments,
    [PROCESS_NAME]: !!read?.ProcessName,
    [PROCESS_TYPE]: !!read?.ProcessType,
    [ADDRESS]: !!read?.Address,
    [GEOLOCATION]: !!read?.Geolocation,
  };

  const PERMITTED_TO_UPDATE = {
    ALL: !!update?.ALL,
    [ID]: !!update?.id,
    [STATUS]: !!update?.status,
    [USER_CREATED]: !!update?.user_created,
    [USER_UPDATED]: !!update?.user_updated,
    [DATE_CREATED]: !!update?.date_created,
    [DATE_UPDATED]: !!update?.date_updated,
    [SERIAL_NUMBER]: !!update?.SerialNo,
    [TAGS]: !!update?.Tags,
    [DEPARTMENT]: !!update?.Department,
    [LAST_ASSESSMENT]: !!update?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!update?.ServiceExpiryDate,
    [MISC]: !!update?.Misc,
    [ASSESSMENTS]: !!update?.Assessments,
    [PROCESS_NAME]: !!update?.ProcessName,
    [PROCESS_TYPE]: !!update?.ProcessType,
    [ADDRESS]: !!update?.Address,
    [GEOLOCATION]: !!update?.Geolocation,
  };

  const PERMITTED_TO_DELETE = {
    ALL: !!Delete?.ALL,
    [ID]: !!Delete?.id,
    [STATUS]: !!Delete?.status,
    [USER_CREATED]: !!Delete?.user_created,
    [USER_UPDATED]: !!Delete?.user_updated,
    [DATE_CREATED]: !!Delete?.date_created,
    [DATE_UPDATED]: !!Delete?.date_updated,
    [SERIAL_NUMBER]: !!Delete?.SerialNo,
    [TAGS]: !!Delete?.Tags,
    [DEPARTMENT]: !!Delete?.Department,
    [LAST_ASSESSMENT]: !!Delete?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!Delete?.ServiceExpiryDate,
    [MISC]: !!Delete?.Misc,
    [ASSESSMENTS]: !!Delete?.Assessments,
    [PROCESS_NAME]: !!Delete?.ProcessName,
    [PROCESS_TYPE]: !!Delete?.ProcessType,
    [ADDRESS]: !!Delete?.Address,
    [GEOLOCATION]: !!Delete?.Geolocation,
  };

  const PERMITTED_TO_SHARE = {
    ALL: !!share?.ALL,
    [ID]: !!share?.id,
    [STATUS]: !!share?.status,
    [USER_CREATED]: !!share?.user_created,
    [USER_UPDATED]: !!share?.user_updated,
    [DATE_CREATED]: !!share?.date_created,
    [DATE_UPDATED]: !!share?.date_updated,
    [SERIAL_NUMBER]: !!share?.SerialNo,
    [TAGS]: !!share?.Tags,
    [DEPARTMENT]: !!share?.Department,
    [LAST_ASSESSMENT]: !!share?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!share?.ServiceExpiryDate,
    [MISC]: !!share?.Misc,
    [ASSESSMENTS]: !!share?.Assessments,
    [PROCESS_NAME]: !!share?.ProcessName,
    [PROCESS_TYPE]: !!share?.ProcessType,
    [ADDRESS]: !!share?.Address,
    [GEOLOCATION]: !!share?.Geolocation,
  };

  const PROCESSES_PERMISSIONS = {
    PERMITTED_TO_CREATE,
    PERMITTED_TO_READ,
    PERMITTED_TO_UPDATE,
    PERMITTED_TO_DELETE,
    PERMITTED_TO_SHARE,
  };

  return { PROCESSES_PERMISSIONS };
}

export default useProcessesPermissions;
