import styled from "styled-components/macro";

import { FileType } from "types/tasks.types";

import { ReactComponent as PDFIcon } from "assets/icons/file-types/pdf.svg";
import { ReactComponent as JPGIcon } from "assets/icons/file-types/jpg.svg";
import { ReactComponent as PNGIcon } from "assets/icons/file-types/png.svg";
import { ReactComponent as GenericFileIcon } from "assets/icons/file.svg";

const Container = styled.div`
  flex-basis: 15%;
  overflow: hidden;

  @media screen and (min-width: 402px) {
    flex-basis: 11%;
  }

  .file-icon {
    width: 3.5rem;
  }
`;

type FileIconProps = {
  fileType: FileType;
};

const { PDF, JPEG, JPG, PNG } = FileType;

function FileIcon({ fileType }: FileIconProps) {
  let icon = <GenericFileIcon className='file-icon' />;

  switch (fileType) {
    case PDF:
      icon = <PDFIcon className='file-icon' />;
      break;
    case PNG:
      icon = <PNGIcon className='file-icon' />;
      break;
    case JPEG:
    case JPG:
      icon = <JPGIcon className='file-icon' />;
      break;
  }

  return <Container>{icon}</Container>;
}

export default FileIcon;
