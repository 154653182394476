import {
  ActionsEnum,
  CollectionEnum,
  PermFields,
  Permission,
} from "types/permissions.types";
import { createPermissionObject, filterByCollection } from "./utils/permissions.utils";

const {
  ID,
  STATUS,
  USER_CREATED,
  USER_UPDATED,
  DATE_CREATED,
  DATE_UPDATED,
  SERIAL_NUMBER,
  TAGS,
  DEPARTMENT,
  LAST_ASSESSMENT,
  SERVICE_EXPIRY_DATE,
  MISC,
  ASSESSMENTS,
  MACHINE_MODEL,
  MACHINE_TYPE,
  ACCORDION,
  SOME_RAW_GROUP,
  MAKE,
  MAKE_YEAR,
  HOUR_METER,
  DOCUMENTS,
} = PermFields;

const { MACHINES } = CollectionEnum;
const { CREATE, DELETE, READ, SHARE, UPDATE } = ActionsEnum;

function useMachinesPermissions(userPermissions: Permission[]) {
  const permissionsArray = filterByCollection(userPermissions, MACHINES);

  const permsGroupedByAction = {
    [CREATE]: createPermissionObject(permissionsArray, CREATE),
    [READ]: createPermissionObject(permissionsArray, READ),
    [UPDATE]: createPermissionObject(permissionsArray, UPDATE),
    [DELETE]: createPermissionObject(permissionsArray, DELETE),
    [SHARE]: createPermissionObject(permissionsArray, SHARE),
  };

  const { create, read, update, share, delete: Delete } = permsGroupedByAction;

  const PERMITTED_TO_CREATE = {
    ALL: !!create?.ALL,
    [ID]: !!create?.id,
    [STATUS]: !!create?.status,
    [USER_CREATED]: !!create?.user_created,
    [USER_UPDATED]: !!create?.user_updated,
    [DATE_CREATED]: !!create?.date_created,
    [DATE_UPDATED]: !!create?.date_updated,
    [SERIAL_NUMBER]: !!create?.SerialNo,
    [TAGS]: !!create?.Tags,
    [DEPARTMENT]: !!create?.Department,
    [LAST_ASSESSMENT]: !!create?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!create?.ServiceExpiryDate,
    [MISC]: !!create?.Misc,
    [ASSESSMENTS]: !!create?.Assessments,
    [MACHINE_MODEL]: !!create?.MachineModel,
    [MACHINE_TYPE]: !!create?.MachineType,
    [ACCORDION]: !!create?.["accordion-cfh2fe"],
    [SOME_RAW_GROUP]: !!create?.somerawgroup,
    [MAKE]: !!create?.Make,
    [MAKE_YEAR]: !!create?.MakeYear,
    [HOUR_METER]: !!create?.HourMeter,
    [DOCUMENTS]: !!create?.Dokumenty,
  };

  const PERMITTED_TO_READ = {
    ALL: !!read?.ALL,
    [ID]: !!read?.id,
    [STATUS]: !!read?.status,
    [USER_CREATED]: !!read?.user_created,
    [USER_UPDATED]: !!read?.user_updated,
    [DATE_CREATED]: !!read?.date_created,
    [DATE_UPDATED]: !!read?.date_updated,
    [SERIAL_NUMBER]: !!read?.SerialNo,
    [TAGS]: !!read?.Tags,
    [DEPARTMENT]: !!read?.Department,
    [LAST_ASSESSMENT]: !!read?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!read?.ServiceExpiryDate,
    [MISC]: !!read?.Misc,
    [ASSESSMENTS]: !!read?.Assessments,
    [MACHINE_MODEL]: !!read?.MachineModel,
    [MACHINE_TYPE]: !!read?.MachineType,
    [ACCORDION]: !!read?.["accordion-cfh2fe"],
    [SOME_RAW_GROUP]: !!read?.somerawgroup,
    [MAKE]: !!read?.Make,
    [MAKE_YEAR]: !!read?.MakeYear,
    [HOUR_METER]: !!read?.HourMeter,
    [DOCUMENTS]: !!read?.Dokumenty,
  };

  const PERMITTED_TO_UPDATE = {
    ALL: !!update?.ALL,
    [ID]: !!update?.id,
    [STATUS]: !!update?.status,
    [USER_CREATED]: !!update?.user_created,
    [USER_UPDATED]: !!update?.user_updated,
    [DATE_CREATED]: !!update?.date_created,
    [DATE_UPDATED]: !!update?.date_updated,
    [SERIAL_NUMBER]: !!update?.SerialNo,
    [TAGS]: !!update?.Tags,
    [DEPARTMENT]: !!update?.Department,
    [LAST_ASSESSMENT]: !!update?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!update?.ServiceExpiryDate,
    [MISC]: !!update?.Misc,
    [ASSESSMENTS]: !!update?.Assessments,
    [MACHINE_MODEL]: !!update?.MachineModel,
    [MACHINE_TYPE]: !!update?.MachineType,
    [ACCORDION]: !!update?.["accordion-cfh2fe"],
    [SOME_RAW_GROUP]: !!update?.somerawgroup,
    [MAKE]: !!update?.Make,
    [MAKE_YEAR]: !!update?.MakeYear,
    [HOUR_METER]: !!update?.HourMeter,
    [DOCUMENTS]: !!update?.Dokumenty,
  };

  const PERMITTED_TO_DELETE = {
    ALL: !!Delete?.ALL,
    [ID]: !!Delete?.id,
    [STATUS]: !!Delete?.status,
    [USER_CREATED]: !!Delete?.user_created,
    [USER_UPDATED]: !!Delete?.user_updated,
    [DATE_CREATED]: !!Delete?.date_created,
    [DATE_UPDATED]: !!Delete?.date_updated,
    [SERIAL_NUMBER]: !!Delete?.SerialNo,
    [TAGS]: !!Delete?.Tags,
    [DEPARTMENT]: !!Delete?.Department,
    [LAST_ASSESSMENT]: !!Delete?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!Delete?.ServiceExpiryDate,
    [MISC]: !!Delete?.Misc,
    [ASSESSMENTS]: !!Delete?.Assessments,
    [MACHINE_MODEL]: !!Delete?.MachineModel,
    [MACHINE_TYPE]: !!Delete?.MachineType,
    [ACCORDION]: !!Delete?.["accordion-cfh2fe"],
    [SOME_RAW_GROUP]: !!Delete?.somerawgroup,
    [MAKE]: !!Delete?.Make,
    [MAKE_YEAR]: !!Delete?.MakeYear,
    [HOUR_METER]: !!Delete?.HourMeter,
    [DOCUMENTS]: !!Delete?.Dokumenty,
  };

  const PERMITTED_TO_SHARE = {
    ALL: !!share?.ALL,
    [ID]: !!share?.id,
    [STATUS]: !!share?.status,
    [USER_CREATED]: !!share?.user_created,
    [USER_UPDATED]: !!share?.user_updated,
    [DATE_CREATED]: !!share?.date_created,
    [DATE_UPDATED]: !!share?.date_updated,
    [SERIAL_NUMBER]: !!share?.SerialNo,
    [TAGS]: !!share?.Tags,
    [DEPARTMENT]: !!share?.Department,
    [LAST_ASSESSMENT]: !!share?.LastAssessment,
    [SERVICE_EXPIRY_DATE]: !!share?.ServiceExpiryDate,
    [MISC]: !!share?.Misc,
    [ASSESSMENTS]: !!share?.Assessments,
    [MACHINE_MODEL]: !!share?.MachineModel,
    [MACHINE_TYPE]: !!share?.MachineType,
    [ACCORDION]: !!share?.["accordion-cfh2fe"],
    [SOME_RAW_GROUP]: !!share?.somerawgroup,
    [MAKE]: !!share?.Make,
    [MAKE_YEAR]: !!share?.MakeYear,
    [HOUR_METER]: !!share?.HourMeter,
    [DOCUMENTS]: !!share?.Dokumenty,
  };

  const MACHINES_PERMISSIONS = {
    PERMITTED_TO_CREATE,
    PERMITTED_TO_READ,
    PERMITTED_TO_UPDATE,
    PERMITTED_TO_DELETE,
    PERMITTED_TO_SHARE,
  };

  return { MACHINES_PERMISSIONS };
}

export default useMachinesPermissions;
