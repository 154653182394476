import { useState, useEffect, HTMLAttributeAnchorTarget } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "react-use-cookie";

import { ACCESS_TOKEN, getTarget } from "utils/utils";
import { CreatedTask, FileType, RelatedFile } from "types/tasks.types";
import { baseURL } from "axios-instance/axios-instance";
import { CountryCodes } from "types/types";

const createHref = (filename: string, token: string) =>
  `${baseURL}assets/${filename.split(".")[0]}?access_token=${token}`;

const getFileType = (filename: string) => filename.split(".")[1] as FileType;

const getAttachments = ({ Related }: CreatedTask) =>
  Related.filter(({ item }) => typeof item !== "string");

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);
  return isPolish ? "Brak danych o załączonych plikach" : "No info about attached files";
};

type Attachment = {
  item: RelatedFile;
};

type RelatedFilesArray = {
  href: string;
  target: HTMLAttributeAnchorTarget;
  title: string;
  filename: string;
  fileType: FileType;
}[];

// --------------------------------------------------------------------

function useRelatedFiles(task: CreatedTask) {
  const [relatedFiles, setRelatedFiles] = useState<RelatedFilesArray>([]);
  const token = getCookie(ACCESS_TOKEN);
  const {
    i18n: { language },
  } = useTranslation();
  const message = getTranslations(language);

  useEffect(() => {
    const attachments = getAttachments(task) as Attachment[];
    let relatedFilesArray: RelatedFilesArray = [];

    if (attachments.length) {
      try {
        relatedFilesArray = attachments.map(({ item: { File } }) => ({
          href: createHref(File.filename_disk, token),
          target: getTarget(),
          title: File.title,
          filename: File.filename_download,
          fileType: getFileType(File.filename_disk),
        }));
      } catch {
        alert(message);
      }
    }

    setRelatedFiles(relatedFilesArray);
  }, [task, token, message]);

  return { relatedFiles };
}

export default useRelatedFiles;
