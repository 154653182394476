import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import logo from "assets/logos/SnapCheck-11.png";

import { LogoContainer, LogoImage, Row } from "../../styles/profile.styles";

type ProfileHeaderProps = {
  onCloseIconClick: () => void;
};

function ProfileHeader({ onCloseIconClick }: ProfileHeaderProps) {
  return (
    <Row>
      <LogoContainer>
        <LogoImage src={logo} alt='' />
      </LogoContainer>

      <CloseIcon className='close-icon' onClick={onCloseIconClick} />
    </Row>
  );
}

export default ProfileHeader;
