import {
  ActionsEnum,
  CollectionEnum,
  PermFields,
  Permission,
} from "types/permissions.types";
import { createPermissionObject, filterByCollection } from "./utils/permissions.utils";

const {
  ID,
  STATUS,
  USER_CREATED,
  USER_UPDATED,
  DATE_CREATED,
  DATE_UPDATED,
  SORT,
  DEPARTMENT_NAME,
  COMPANY,
} = PermFields;

const { DEPARTMENTS } = CollectionEnum;
const { CREATE, DELETE, READ, SHARE, UPDATE } = ActionsEnum;

function useDepartmentsPermissions(userPermissions: Permission[]) {
  const permissionsArray = filterByCollection(userPermissions, DEPARTMENTS);

  const permsGroupedByAction = {
    [CREATE]: createPermissionObject(permissionsArray, CREATE),
    [READ]: createPermissionObject(permissionsArray, READ),
    [UPDATE]: createPermissionObject(permissionsArray, UPDATE),
    [DELETE]: createPermissionObject(permissionsArray, DELETE),
    [SHARE]: createPermissionObject(permissionsArray, SHARE),
  };

  const { create, read, update, share, delete: Delete } = permsGroupedByAction;

  const PERMITTED_TO_CREATE = {
    ALL: !!create?.ALL,
    [ID]: !!create?.id,
    [STATUS]: !!create?.status,
    [USER_CREATED]: !!create?.user_created,
    [USER_UPDATED]: !!create?.user_updated,
    [DATE_CREATED]: !!create?.date_created,
    [DATE_UPDATED]: !!create?.date_updated,
    [SORT]: !!create?.sort,
    [DEPARTMENT_NAME]: !!create?.DepartmentName,
    [COMPANY]: !!create?.Company,
  };

  const PERMITTED_TO_READ = {
    ALL: !!read?.ALL,
    [ID]: !!read?.id,
    [STATUS]: !!read?.status,
    [USER_CREATED]: !!read?.user_created,
    [USER_UPDATED]: !!read?.user_updated,
    [DATE_CREATED]: !!read?.date_created,
    [DATE_UPDATED]: !!read?.date_updated,
    [SORT]: !!read?.sort,
    [DEPARTMENT_NAME]: !!read?.DepartmentName,
    [COMPANY]: !!read?.Company,
  };

  const PERMITTED_TO_UPDATE = {
    ALL: !!update?.ALL,
    [ID]: !!update?.id,
    [STATUS]: !!update?.status,
    [USER_CREATED]: !!update?.user_created,
    [USER_UPDATED]: !!update?.user_updated,
    [DATE_CREATED]: !!update?.date_created,
    [DATE_UPDATED]: !!update?.date_updated,
    [SORT]: !!update?.sort,
    [DEPARTMENT_NAME]: !!update?.DepartmentName,
    [COMPANY]: !!update?.Company,
  };

  const PERMITTED_TO_DELETE = {
    ALL: !!Delete?.ALL,
    [ID]: !!Delete?.id,
    [STATUS]: !!Delete?.status,
    [USER_CREATED]: !!Delete?.user_created,
    [USER_UPDATED]: !!Delete?.user_updated,
    [DATE_CREATED]: !!Delete?.date_created,
    [DATE_UPDATED]: !!Delete?.date_updated,
    [SORT]: !!Delete?.sort,
    [DEPARTMENT_NAME]: !!Delete?.DepartmentName,
    [COMPANY]: !!Delete?.Company,
  };

  const PERMITTED_TO_SHARE = {
    ALL: !!share?.ALL,
    [ID]: !!share?.id,
    [STATUS]: !!share?.status,
    [USER_CREATED]: !!share?.user_created,
    [USER_UPDATED]: !!share?.user_updated,
    [DATE_CREATED]: !!share?.date_created,
    [DATE_UPDATED]: !!share?.date_updated,
    [SORT]: !!share?.sort,
    [DEPARTMENT_NAME]: !!share?.DepartmentName,
    [COMPANY]: !!share?.Company,
  };

  const DEPARTMENTS_PERMISSIONS = {
    PERMITTED_TO_CREATE,
    PERMITTED_TO_READ,
    PERMITTED_TO_UPDATE,
    PERMITTED_TO_DELETE,
    PERMITTED_TO_SHARE,
  };

  return { DEPARTMENTS_PERMISSIONS };
}

export default useDepartmentsPermissions;
