import styled, { css } from "styled-components/macro";

export const Container = styled.div<{ animate: boolean; duration: string }>`
  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

export const Wrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.primary_200};
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
`;

export const IconContainer = styled.div`
  width: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 100%;
    height: 100%;
    transform: translateY(1.3rem);

    path {
      fill: ${({ theme }) => theme.primary_500};
    }
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.primary_500};
  text-align: center;
  font-family: GothamBold;
  font-size: 1.8rem;
  padding: 1rem 0;
`;
