import { CountryCodes } from "types/types";

export const getTranslations = (language: string) => {
  const isPolish = language.includes(CountryCodes.PL);

  let labels = {
    header: "Create task",
    title: "Description",
    comment: "Comment",
    question: "Quest",
    button: {
      create: "Create",
    },
  };

  let modal = {
    message: "Are you sure you want to exit the editor?",
    button: "Exit",
    header: {
      Title: "Description",
      Comment: "Comment",
    },
  };

  let alert = {
    success: "Task created!",
    error: "Unable to create task",
  };

  let titles = {
    editTitle: "Edit description",
  };

  let placeholders = {
    title: "Enter description",
  };

  if (isPolish) {
    labels = {
      header: "Tworzenie zadania",
      title: "Opis",
      comment: "Komentarz",
      question: "Pyt",
      button: {
        create: "Utwórz",
      },
    };

    modal = {
      message: "Czy na pewno chcesz wyjść z edytora?",
      button: "Wyjdź",
      header: {
        Title: "Opis",
        Comment: "Komentarz",
      },
    };

    alert = {
      success: "Utworzono zadanie!",
      error: "Nie można utworzyć zadania",
    };

    titles = {
      editTitle: "Edytuj opis",
    };

    placeholders = {
      title: "Dodaj opis",
    };
  }

  return { labels, modal, alert, titles, placeholders };
};
