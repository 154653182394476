import { useState, useRef } from "react";
import styled, { css } from "styled-components/macro";

import useRelatedFiles from "hooks/useRelatedFiles";
import useClickOutside from "hooks/useClickOutside";
import { CreatedTask } from "types/tasks.types";
import { isMobile } from "utils/utils";

import { ReactComponent as GenericFileIcon } from "assets/icons/file-duo.svg";

import FileIcon from "./components/file-icon/FileIcon";

const Container = styled.div`
  position: absolute;
  bottom: 0.75rem;
  z-index: ${({ theme }) => theme.level2};
`;

const AttachmentIconContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  transform: translate(-0.8rem, 0.8rem);
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  .attachment-icon {
    width: 5.5rem;
  }
`;

const FilesNumber = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  color: ${({ theme }) => theme.primary};
  font-family: GothamBold;
`;

const FilesMenu = styled.ul`
  ${({ theme }) => theme.cardShadow};
  border-radius: 0.6rem;
  overflow: hidden;
  background-color: ${({ theme }) => theme.white};
`;

const FilesMenuItem = styled.li<{ isMobile: boolean }>`
  position: relative;
  padding-right: 2rem;
  padding-left: 1rem;
  transition: background-color 0.15s ease-in-out;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  &:not(:last-of-type) {
    &::after {
      content: "";
      position: absolute;
      width: 90%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 1px;
      background-color: ${({ theme }) => theme.primary_100};
    }
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.primary_50};
      }
    `}
`;

const FileLink = styled.a`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;

  &:link,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.primary};
  }
`;

const FileName = styled.span`
  height: 4rem;
  flex-basis: 85%;

  @media screen and (min-width: 402px) {
    flex-basis: 89%;
  }

  overflow: hidden;
  ${({ theme }) => theme.ellipsisMultiline};
`;

type RelatedFilesProps = {
  task: CreatedTask;
  permittedToReadRelated: boolean;
};

function RelatedFiles({ task, permittedToReadRelated }: RelatedFilesProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { relatedFiles } = useRelatedFiles(task);
  const ref = useRef(null);

  const filesNumber = relatedFiles.length;

  let content = null;

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  useClickOutside(ref, toggleMenu);

  if (!permittedToReadRelated) {
    return null;
  }

  if (filesNumber) {
    content = (
      <AttachmentIconContainer isMobile={isMobile} onClick={toggleMenu}>
        <GenericFileIcon className='attachment-icon' />
        <FilesNumber>{filesNumber}</FilesNumber>
      </AttachmentIconContainer>
    );
  }

  if (menuOpen) {
    content = (
      <FilesMenu ref={ref}>
        {relatedFiles.map(({ href, target, title, fileType }, i) => (
          <FilesMenuItem key={href} isMobile={isMobile} onClick={toggleMenu}>
            <FileLink href={href} target={target} rel='noreferrer'>
              <FileIcon fileType={fileType} />
              <FileName>{title}</FileName>
            </FileLink>
          </FilesMenuItem>
        ))}
      </FilesMenu>
    );
  }

  return <Container>{content}</Container>;
}

export default RelatedFiles;
