import { CollectionEnum } from "./permissions.types";

export enum EditModalTypes {
  TITLE = "Title",
  COMMENT = "Comment",
}

export enum ReturnPathKeys {
  SELECTED_TASK = "selectedTaskReturnPath",
  TASK_CREATOR = "taskCreatorReturnPath",
  CHANGE_STATUS = "changeStatusReturnPath",
}

export enum TaskStatuses {
  TO_DO = "todo",
  IN_PROGRESS = "inprogress",
  DONE = "done",
  ARCHIVED = "archived",
}

export type RelatedFile = {
  File: { filename_disk: string; filename_download: string; title: string };
};

export type CreatedTask = {
  AssignTo: string;
  Company: string;
  Deadline: string | null;
  Description: string;
  Files: File[];
  Related: {
    item: string | RelatedFile;
  }[];
  Title: string;
  date_created: Date | number;
  date_updated: Date;
  id: string;
  sort: number | null;
  status: TaskStatuses;
  user_created: string;
  user_updated: string;
};

export enum FileType {
  PDF = "pdf",
  PNG = "png",
  JPG = "jpg",
  JPEG = "jpeg",
}

export type GetCreatedTasksResponse = {
  data: CreatedTask[];
};

enum AssessmentsCollection {
  ASSESSMENTS = "Assessments",
}

type TaskCollections = CollectionEnum | AssessmentsCollection;

export type Create = {
  Tasks_id: string;
  collection: TaskCollections;
  item: {
    id: string;
  };
}[];

export type Related = {
  create: Create;
};

export type CreateTaskPayload = {
  AnswersLinkWithTask: string[];
  Title: string;
  Description: string;
  AssignTo: string | null;
  Related: Related;
  Deadline: string | null;
  status: TaskStatuses;
  token: string;
};

export type TaskStatusOption = {
  id: TaskStatuses;
  label: string | JSX.Element;
  value: TaskStatuses;
};

export type EditTaskPayload = {
  id: string;
  AssignTo?: string;
  Description?: string;
  Deadline?: string | null;
  Title?: string;
  status?: TaskStatuses;
  token: string;
};

export type EditTaskResponse = {
  data: CreatedTask;
};

export enum TaskPermissionCollections {
  TASKS = "Tasks",
  TASKS_RELATED = "Tasks_Related",
  TASKS_FILES = "Tasks_files",
}
