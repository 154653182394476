import { instance } from "axios-instance/axios-instance";

import { AssignDepartmentToUserPayload, User, UserInfoResponse } from "types/types";
import { UserPermissionsResponse } from "types/permissions.types";

// const url_old =
//   "/users/me?fields=id,first_name,last_name,email,language,avatar.id,role.name,role.id,Department,Department.id,Department.DepartmentName,Department.Company,Department.Company.id,Department.Company.CompanyName,Departments.Departments_id.DepartmentName,Departments.Departments_id.Company.id,Departments.Departments_id.Company.CompanyName";
const url_new =
  "/users/me/?fields=id,first_name,last_name,email,language,avatar,role.name,role.id,Departments.id,Departments.Departments_id.DepartmentName,Departments.Departments_id.id,Departments.Departments_id.Company.id,Departments.Departments_id.Company.CompanyName,Company.*.Department.id,Department.DepartmentName,Department.Company.*";

export const getUserInfo = (token: string) => async () => {
  const { data } = await instance.get<UserInfoResponse>(url_new, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export const getUserPermissions = (token: string) => async () => {
  const { data } = await instance.get<UserPermissionsResponse>(
    // `/permissions?filter[role][_eq]=${roleId}&limit=-1&fields=id,collection,action,fields`,
    // `/permissions?filter[collection][_in]=Machines,Processes&filter[action][_eq]=update&limit=-1&fields=collection,action,fields`,
    `/permissions?filter[collection][_in]=Machines,Processes,Tasks,Tasks_files,Tasks_Related,Departments&filter[action][_in]=create,read,update,share,delete&limit=-1&fields=collection,action,fields`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data;
};

export const getUsers = (token: string) => async () => {
  const { data } = await instance.get<{ data: User[] }>(
    "/users?fields[]=first_name&fields[]=last_name&fields[]=email&fields[]=id&fields[]=avatar&limit=1000&sort=last_name,first_name",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return data.data;
};

export const assignDepartmentToUser = async (payload: AssignDepartmentToUserPayload) => {
  const { token, userId, ...body } = payload;

  const { data } = await instance.patch<any>(`/users/${userId}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};
