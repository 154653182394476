import { ChangeEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components/macro";
import { isAfter } from "date-fns";

import { formatDate } from "utils/utils";
import { getTranslations } from "pages/user-tasks/translations/tasks.translations";

import Input from "components/atoms/Input";

import { StyledLabel } from "styles/generalStyles";

const Container = styled.div<{ isEditable: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;

  ${({ isEditable }) =>
    isEditable &&
    css`
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    `}
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const Deadline = styled.span<{ isOverdue: boolean }>`
  ${({ isOverdue }) =>
    isOverdue &&
    css`
      color: ${({ theme }) => theme.errorColor};
    `}
`;

type TaskDeadlineProps = {
  permittedToReadDeadline: boolean;
  permittedToEditDeadline: boolean;
  taskDeadline: string | null;
  onDealineChange: (value: string | null) => void;
};

const Wrapper = ({
  children,
  isEditable,
}: {
  children: ReactNode;
  isEditable: boolean;
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const {
    labels: { deadline },
  } = getTranslations(language);

  return (
    <Container isEditable={isEditable}>
      <StyledLabel>{deadline}:</StyledLabel>
      {children}
    </Container>
  );
};

function TaskDeadline({
  permittedToReadDeadline,
  permittedToEditDeadline,
  taskDeadline,
  onDealineChange,
}: TaskDeadlineProps) {
  const isOverdue = isAfter(new Date(), new Date(taskDeadline as string));

  let content = null;

  const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    let deadline = null;

    if (value) {
      deadline = value;
    }

    onDealineChange(deadline);
  };

  if (permittedToReadDeadline) {
    if (permittedToEditDeadline) {
      content = (
        <Wrapper isEditable={permittedToEditDeadline}>
          <InputWrapper>
            <Input type='datetime-local' value={taskDeadline ?? ""} onChange={onChange} />
          </InputWrapper>
        </Wrapper>
      );
    } else {
      content = taskDeadline ? (
        <Wrapper isEditable={permittedToEditDeadline}>
          <Deadline isOverdue={isOverdue}>{formatDate(taskDeadline as string)}</Deadline>
        </Wrapper>
      ) : null;
    }
  }

  return content;
}

export default TaskDeadline;
