import { useEffect, useState } from "react";
import * as Bowser from "bowser";

import { DEVICE_INFO_STRING, isAPK, isPWA } from "utils/utils";
import { DeviceInfo, Theme, UserAgent } from "types/types";
import useDeviceUuid from "./useDeviceUuid";

const deviceInfoInitial = {
  browser: {
    name: "",
    version: "",
  },
  os: {
    name: "",
    version: "",
    versionName: "",
  },
  platform: {
    type: "",
  },
  engine: {
    name: "",
  },
  phoneModel: "",
  theme: "Default" as Theme,
  app: "",
  resolution: {
    width: 0,
    height: 0,
  },
  appWindowSize: {
    innerWidth: 0,
    innerHeight: 0,
  },
};

const getLocalData = () => localStorage.getItem(DEVICE_INFO_STRING) ?? "";

function useDeviceAndAppInfo() {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(deviceInfoInitial);
  const [deviceInfoString, setDeviceInfoString] = useState(getLocalData());
  const { deviceUuid } = useDeviceUuid();

  const setUpDeviceAndAppInfo = async () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const info = browser.getResult() as UserAgent;
    let app = "web app";

    if (isPWA) {
      app = "PWA";
    }

    if (isAPK) {
      app = "APK";
    }

    const { width, height } = window.screen;
    const { innerWidth, innerHeight } = window;

    const resolution = { width, height };
    const appWindowSize = {
      innerWidth,
      innerHeight,
    };

    let theme: Theme;

    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        theme = "Dark";
      } else {
        theme = "Light";
      }
    } else {
      theme = "Default";
    }

    if (info.platform.type === "desktop" && width < height) {
      setDeviceInfo({
        ...info,
        platform: { ...info.platform, type: "tablet" },
        theme,
        app,
        resolution,
        appWindowSize,
        phoneModel: "",
      });
    } else {
      setDeviceInfo({ ...info, theme, app, resolution, appWindowSize, phoneModel: "" });
    }

    try {
      window.navigator.userAgentData
        ?.getHighEntropyValues([
          "architecture",
          "model",
          "platform",
          "platformVersion",
          "fullVersionList",
        ])
        .then((ua: UADataValues) => {
          const phoneModel = ua["model"];

          if (phoneModel) {
            setDeviceInfo((prev) => ({ ...prev, phoneModel }));
          }
        });
    } catch {}
  };

  useEffect(() => {
    setUpDeviceAndAppInfo();
  }, []);

  useEffect(() => {
    const {
      browser,
      os,
      platform,
      theme,
      app,
      resolution: { width, height },
      appWindowSize: { innerWidth, innerHeight },
      phoneModel,
    } = deviceInfo;

    const devInfoStr = `${browser.name} ${browser.version} | ${os.name} ${
      os.versionName || os.version
    } | ${
      platform.type
    } ${phoneModel} | ${app} | ${theme} | ${width} x ${height} | ${innerWidth} x ${innerHeight} | ${deviceUuid}`;

    setDeviceInfoString(devInfoStr);
  }, [deviceInfo, deviceUuid]);

  useEffect(() => {
    if (deviceInfoString) {
      localStorage.setItem(DEVICE_INFO_STRING, deviceInfoString);
    }
  }, [deviceInfoString]);

  return { deviceInfo, deviceUuid, deviceInfoString, setUpDeviceAndAppInfo };
}

export default useDeviceAndAppInfo;
