import styled, { css } from "styled-components/macro";

import { device } from "utils/utils";

export const ContentContainer = styled.div<{
  alarm?: boolean;
  background_100?: boolean;
}>`
  width: 100%;
  max-width: 45rem;
  margin: 0 auto;
  padding: 0 2rem;
  color: ${({ theme }) => theme.primary};
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => theme.scrollbarFirefox}
  ${({ theme }) => theme.fillUpRemainingSpace}
  background-color:${({ theme }) => theme.primary_50};

  -webkit-box-shadow: 0px 0px 10px 0px rgba(33, 47, 90, 0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(33, 47, 90, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(33, 47, 90, 0.2);

  @media ${device.iPad} {
    max-width: 65rem;
    height: 95%;
    margin-top: 2rem;
    border-radius: 0.6rem;
    ${({ theme }) => theme.cardShadow};
  }

  ${({ alarm }) =>
    alarm &&
    css`
      @media ${device.iPad} {
        justify-content: center;
      }
    `}

  ${({ background_100 }) =>
    background_100 &&
    css`
      background-color: ${({ theme }) => theme.primary_100};
    `}
`;

export const Wrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.fillUpRemainingSpace};
`;

export const CheckListsContainer = styled.ul`
  list-style: none;
  overflow-y: auto;
  padding-bottom: 1rem;
  ${({ theme }) => theme.scrollbarFirefox}
`;

export const Form = styled.form<{ login?: boolean }>`
  ${({ login }) =>
    login &&
    css`
      margin-top: 0.5rem;

      @media screen and (min-height: 508px) {
        margin-top: 2rem;
      }

      @media screen and (min-height: 616px) {
        margin-top: 0;
      }
    `}
`;

export const FormField = styled.fieldset<{ noMargin?: boolean }>`
  margin-bottom: 1rem;

  /* This is to override react-select styles for selected option in dropdown group, */
  /* which is: color: hsl(0, 0%, 100%) - it makes the option invisible */
  div[aria-selected="true"] {
    color: ${({ theme }) => theme.primary};
  }

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-bottom: 0;
    `}
`;

export const Header = styled.div<{
  upperCase?: boolean;
  standardFont?: boolean;
}>`
  font-size: 2.2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ standardFont }) => (standardFont ? "GothamBold" : "AuraAspect")};
  opacity: 0.8;
  text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "none")};
  position: relative;

  @media screen and (min-height: 571px) {
    font-size: 2.4rem;
    padding: 2rem 0;
  }

  @media ${device.iPad} {
    font-size: 2.6rem;
  }
`;

export const ArrowContainer = styled.div<{ isMobile: boolean }>`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;

  .arrow {
    width: 2.7rem;
    height: 2.7rem;
    cursor: ${({ isMobile }) => (!isMobile ? "pointer" : "none")};

    @media screen and (min-height: 616px) {
      width: 3rem;
      height: 3rem;
    }
  }
`;

export const InputContainer = styled.div<{
  password?: boolean;
  marginBottom?: boolean;
}>`
  position: relative;
  padding-bottom: 0.5rem;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "1rem" : 0)};

  .clear-icon,
  .eye-icon {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    right: 0.5rem;
    bottom: 1.7rem;
    opacity: 0.75;
    cursor: pointer;

    @media screen and (min-height: 508px) {
      right: 1rem;
      bottom: 2.2rem;
    }
  }

  .clear-icon {
    path {
      fill: ${({ theme }) => theme.primary};
    }
  }

  .eye-icon {
    z-index: ${({ theme }) => theme.level1};
  }
`;

export const Error = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.errorColor};
  font-size: 1.2rem;
`;

const Content = styled.div`
  padding-top: 1.5rem;
  min-width: 27rem;
  max-width: 32rem;

  @media (min-width: 321px) {
    min-width: 30rem;
    max-width: 30rem;
  }

  @media screen and (min-width: 380px) {
    min-width: 30rem;
    max-width: 32rem;
  }

  @media ${device.iPad} {
    min-width: 35rem;
    max-width: 50rem;
  }
`;

const Message = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary};

  div:first-of-type {
    margin-bottom: 0.2rem;
  }
`;

const Actions = styled.div<{
  spaceBetween?: boolean;
  center?: boolean;
  noMargin?: boolean;
}>`
  display: flex;
  justify-content: ${({ spaceBetween, center }) =>
    spaceBetween ? "space-between" : center ? "center" : "flex-end"};
  margin-top: ${({ noMargin }) => (noMargin ? "0" : "2rem")};
  gap: 1rem;
`;

export const ButtonContainer = styled.div<{
  long?: boolean;
  middle?: boolean;
  double?: boolean;
}>`
  @media ${device.iPhoneSE} {
    width: ${({ long, middle, double }) =>
      long ? "15rem" : middle ? "12rem" : double ? "11rem" : "10rem"};
  }

  @media (min-width: 321px) {
    width: ${({ long, middle, double }) =>
      long ? "20rem" : middle ? "15rem" : double ? "12rem" : "10rem"};
  }
`;

export const ModalStyles = {
  Content,
  Message,
  Actions,
  ButtonContainer,
};

export const Loader = styled.div`
  font-style: italic;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.span`
  margin-left: 0.5rem;
`;

export const EnterSnBtnContainer = styled.ul`
  margin-top: 1.2rem;

  @media screen and (min-height: 616px) {
    margin-top: 2rem;
  }
`;

export const OptionsContainer = styled.ul<{ marginTop?: boolean }>`
  overflow-y: auto;
  padding-bottom: 1rem;
  margin-top: ${({ marginTop }) => (marginTop ? "2rem" : "1rem")};

  ${({ theme }) => theme.scrollbarFirefox}
`;

export const Option = styled.li<{
  large?: boolean;
  alarm?: boolean;
  backgroundColour?: string | null;
}>`
  height: 10rem;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 0.6rem;
  box-shadow: 1px 4px 9px -2px rgba(0, 0, 0, 0.2);
  position: relative;
  user-select: none;
  transition: filter 0.2s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.white};
  border: 2px solid;
  cursor: pointer;

  ${({ alarm }) =>
    alarm &&
    css<{ backgroundColour?: string | null }>`
      background-color: ${({ backgroundColour, theme }) =>
        backgroundColour ? backgroundColour : theme.alarmColor};
      text-shadow: 1px 1px 1px #333;
      height: 8rem;
    `}

  ${({ large }) =>
    large &&
    css`
      @media screen and (min-height: 616px) {
        height: 12rem;
      }
    `}

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &:hover {
    filter: brightness(1.1);
  }

  .icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    fill: ${({ theme }) => theme.white};
  }

  .phone {
    left: 0.5rem;
    width: 2.7rem;
    height: 2.7rem;
  }
`;

export const OptionLabel = styled.div`
  font-family: GothamBold;
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0 1rem;

  @media screen and (min-height: 616px) {
    font-size: 1.6rem;
  }
`;

export const Divider = styled.div`
  height: 1.2rem;

  @media screen and (min-height: 616px) {
    height: 2rem;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea<{ smallFont?: boolean; marginBottom?: boolean }>`
  width: 100%;
  padding: 1rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  font-size: ${({ smallFont }) => (smallFont ? "1.6rem" : "2rem")};
  resize: none;
  color: ${({ theme }) => theme.primary};

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 2.5rem;
    `}

  &:disabled {
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid hsl(0, 0%, 60%);
  }

  ::placeholder {
    font-size: 2rem;
    opacity: 0.5;
  }

  @media screen and (min-height: 616px) {
    font-size: 2.2rem;

    ${({ smallFont }) =>
      smallFont &&
      css`
        font-size: 1.8rem;
      `}
  }
`;

export const StyledLabel = styled.div<{ required?: boolean }>`
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: GothamBold;

  ${({ required }) =>
    required &&
    css`
      &::before {
        content: "*";
      }
    `}
`;

export const Card = styled.div<{
  paddingTop?: boolean;
  noBottomSpace?: boolean;
  width?: number;
}>`
  margin-top: 1rem;
  padding-top: ${({ paddingTop }) => (paddingTop ? "2rem" : "1rem")};
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 0.6rem;
  transform: translateY(-0.5rem);
  background-color: rgba(196, 205, 222, 0.25);
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(17, 20, 22, 0.15) 0px 0px 0px 1px;
  position: relative;
  z-index: ${({ theme }) => theme.level1};

  ${({ width }) =>
    width &&
    css<{ width?: number }>`
      width: ${({ width }) => `${width}%`};
    `}

  ${({ noBottomSpace }) =>
    !noBottomSpace &&
    css`
      padding-bottom: 2.25rem;
    `}

  @media screen and (min-height: 616px) {
    transform: translateY(0);
  }

  @media ${device.iPad} {
    padding-top: 2rem;
    padding-right: 2.5rem;
    padding-bottom: 2rem;
    padding-left: 2.5rem;
  }
`;
