import {
  ActionsEnum,
  PermObject,
  Permission,
  CollectionEnum,
} from "types/permissions.types";

export const PERM_OBJECT_INIT: Partial<PermObject> = {};

export const filterByCollection = (
  permissions: Permission[],
  collectionName: CollectionEnum,
) => permissions.filter(({ collection }) => collection.includes(collectionName));

export const createPermissionObject = (
  permissionsArray: Permission[],
  actionPayload: ActionsEnum,
) => {
  const actionObject = permissionsArray.find(({ action }) => action === actionPayload);

  if (actionObject) {
    if (!actionObject.fields) return {};

    return actionObject.fields.reduce((acc, curr) => {
      if (curr === "*") {
        acc = { ALL: true };
      } else {
        acc = {
          ...acc,
          [curr]: true,
        };
      }

      return acc;
    }, PERM_OBJECT_INIT);
  }
};
