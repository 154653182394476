import styled, { css } from "styled-components/macro";
import { device } from "utils/utils";

export const InfoCard = styled.div`
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textColor1};
  padding-right: 2.5rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  border-radius: 0.6rem;
  margin-top: 1.8rem;
  ${({ theme }) => theme.cardShadow};
`;

export const LogoContainer = styled.div`
  width: 17rem;
  height: 5rem;
`;

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-left: -1.5rem;
`;

export const Row = styled.div<{
  alignRight?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  department?: boolean;
  clickable?: boolean;
  isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignRight }) => (alignRight ? "flex-end" : "space-between")};
  color: inherit;
  padding: ${({ noPadding }) => (!noPadding ? "1rem 0" : "0")};
  position: relative;
  border-bottom: ${({ theme }) => `1px solid ${theme.jetGrey}`};

  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: none;
    `}

  .close-icon {
    width: 3rem;
    height: 3rem;
    fill: ${({ theme }) => theme.white};

    position: absolute;
    top: 1rem;
    right: -0.5rem;

    cursor: pointer;
  }

  .copyright-container {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary_100};
  }

  ${({ department }) =>
    department &&
    css`
      padding-bottom: 0;
    `}

  ${({ clickable }) =>
    clickable &&
    css<{ isMobile?: boolean }>`
      cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
      transition: background-color 0.15s ease-out;

      &:hover {
        background-color: rgba(43, 64, 114, 0.2);
      }
    `}
`;

export const Label = styled.span`
  margin-right: 0.5rem;
  font-size: 1.4rem;
  user-select: none;

  @media ${device.iPad} {
    font-size: 1.6rem;
  }
`;

export const Value = styled.span<{ smallFont?: boolean; email?: boolean }>`
  font-size: ${({ smallFont }) => (smallFont ? "1.4rem" : "1.6rem")};
  max-width: 85%;
  ${({ theme }) => theme.ellipsis}
  ${({ email }) =>
    email &&
    css`
      max-width: 100%;
    `}


  .year {
    font-size: 1.4rem;
    padding-top: 0.1rem;

    @media ${device.iPad} {
      font-size: 1.6rem;
      padding-top: 0;
    }
  }

  @media ${device.iPad} {
    font-size: ${({ smallFont }) => (smallFont ? "1.8rem" : "2rem")};
  }
`;

export const CompanyDeptContainer = styled.div`
  width: 100%;
`;

export const DepartmentsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CompanyName = styled.div`
  font-size: 1.4rem;
  font-style: italic;
  width: 100%;
  padding-right: 1rem;
  ${({ theme }) => theme.ellipsis}

  @media ${device.iPad} {
    font-size: 1.8rem;
  }
`;

export const UserDetails = styled.div`
  position: relative;

  .user-icon {
    fill: ${({ theme }) => theme.primary_200};
    width: 3rem;
    height: 3rem;
  }
`;

export const AvatarContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
`;

export const Loader = styled.div<{ width: number }>`
  height: 1.6rem;
  width: ${({ width }) => `${width}rem`};
  position: relative;
  background-color: ${({ theme }) => theme.snapCheckBlueLight};
  overflow: hidden;
  border-radius: 0.4rem;
  opacity: 0.6;
`;

export const Shimmer = styled.div`
  ${({ theme }) => theme.shimmerEffect}
`;
