import { HTMLAttributeAnchorTarget } from "react";

import {
  AccidentFormQuestion,
  Answer,
  CountryCodes,
  MachineProcessStatus,
  QuestionObject,
  QUESTION_TYPE_IDS,
} from "types/types";
import { format } from "date-fns";

export const PORTRAIT = "portrait";
export const LANDSCAPE = "landscape";

export const ACCESS_TOKEN = "snapcheck_access_token";
export const REFRESH_TOKEN = "snapcheck_refresh_token";

export const isMobile = window.matchMedia("(pointer:coarse)").matches;

const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const checkIfEmailValid = (email: string) => EMAIL_REGEX.test(email);

export const getSize = (value: string | string[] | null) => {
  let size = 0;

  if (value) {
    if (typeof value === "string") {
      size = new Blob([value]).size;
    } else {
      let total = 0;

      for (const element of value) {
        total = total + new Blob([element]).size;
      }

      size = total;
    }
  }

  return size;
};

export const getReturnPath = () => {
  const returnPath = localStorage.getItem("returnPath");

  return returnPath ? returnPath : "";
};

export const removeEscapeCharacters = (value: string | undefined) =>
  value ? value.replace(/\\/g, "") : "";

export const device = {
  iPad: `(min-height: 1200px)`,
  iPhoneSE: `(max-width: 320px)`,
};

type ColorMap = {
  [key: string]: string;
};

export const colorMap: ColorMap = {
  A: "#007f7f", // Aqua
  B: "#0000ff", // Blue
  C: "#007f7f", // CadetBlue
  Ć: "#587156", // Cactus
  D: "#08457E", // Dark Cerulean
  E: "#483C3A", // Echo
  F: "#7F626D", // Falcon
  G: "#45634B", // Gecko
  H: "#663854", // Halaya Ube
  I: "#5A4FCF", // Iris
  J: "#145346", // Jackpot
  K: "#E9072B", // Kraken Red Alert
  L: "#029D04", // Laser Green
  Ł: "#967BB6", // Lavender Purple
  M: "#6050DC", // Majorelle Blue
  N: "#B1B9D3", // Nemo
  O: "#02866F", // Observatory
  Ó: "#004765", // Ocean Dark Blue
  P: "#F984E5", // Pale Magenta
  Q: "#BFC2A1", // Quaking Grass
  R: "#B9C8AC", // Rainee
  S: "#097F4B", // Salem
  Ś: "#547377", // Sabbatical
  T: "#E97C07", // Tahiti Gold
  U: "#8878C3", // Ube
  V: "#5D2E3C", // Verve
  W: "#0071CE", // Walmart Blue
  X: "#681B2B", // X Factor
  Y: "#2A5A6E", // Yachting
  Z: "#413A5D", // Zodiac
  Ż: "#EBC2AF", // Zinnwaldite
  Ź: "#B0B5B9", // Zippered Gray
};

const defaultItems = [
  "answers",
  "accidentAnswers",
  "returnPath",
  "profileReturnPath",
  "selectedQuestionnaire",
  "selectedAccidentForm",
  "serialNumberPath",
  "serialNumber",
  "machineProcess",
  "currentPath",
  "userId",
  "tasksReturnPath",
  "alarmsReturnPath",
  "assignedReturnPath",
  "changeStatusReturnPath",
];

export const clearLocalStorage = (items = defaultItems) => {
  for (const item of items) {
    localStorage.removeItem(item);
  }
  dispatchEvent(new StorageEvent("storage"));
};

export const getBrowserName = () => {
  if (
    (navigator.userAgent.indexOf("Opera") !== -1 ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "firefox";
  } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export const getAndroidVersion = () => {
  let androidV = null;
  const ua = navigator.userAgent;

  if (ua.indexOf("Android") >= 0) {
    androidV = parseFloat(ua.slice(ua.indexOf("Android") + 8));
  }

  return androidV;
};

const convertRegExToString = (arg: RegExp) => `${arg}`.split("/")[1];

export const getOsName = () => {
  let osName = "";

  if (isMobile) {
    const osNamesRegExArray = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    const osArray = osNamesRegExArray.map((item) => {
      let name = "";

      if (navigator.userAgent.match(item)) {
        name = convertRegExToString(item);
      }

      return name;
    });

    osName = osArray.filter((item) => !!item)[0];
  } else {
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) {
      osName = "Windows 10";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1) {
      osName = "Windows 8.1";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) {
      osName = "Windows 8";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) {
      osName = "Windows 7";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) {
      osName = "Windows Vista";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) {
      osName = "Windows XP";
    }

    if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) {
      osName = "Windows 2000";
    }

    if (window.navigator.userAgent.indexOf("Mac") !== -1) {
      osName = "Mac/iOS";
    }

    if (window.navigator.userAgent.indexOf("X11") !== -1) {
      osName = "UNIX";
    }

    if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      osName = "Linux";
    }
  }

  return osName;
};

export const getCurrentYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const isPWA = window.matchMedia("(display-mode: standalone)").matches;

export const getRandomNumber = (min = 500, max = 2000) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const convertBytes = (bytes: number) => {
  const sizes = ["Bytes", "kB", "MB", "GB", "TB"];

  if (bytes === 0) {
    return "n/a";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  if (i === 0) {
    return bytes + " " + sizes[i];
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

export const isIOS = /(ipad|iphone|ipod|mac)/g.test(navigator.userAgent.toLowerCase());

export const findAnswer = (
  answersArray: Answer[],
  currentQuestionId: string | undefined,
) => answersArray.find(({ Question }) => Question === currentQuestionId);

export const findAnswerIndex = (
  answersArray: Answer[],
  currentQuestionId: string | undefined,
) => answersArray.findIndex(({ Question }) => Question === currentQuestionId);

export const focusElement = (el: HTMLElement | null) => {
  if (el) {
    el.focus();
  }
};

export const placeCursorAtTheEnd = (
  el: HTMLTextAreaElement | HTMLInputElement,
  textLength: number,
) => {
  el.selectionStart = textLength;
  el.selectionEnd = textLength;
};

export const scrollToBottom = (el: HTMLElement | null) => {
  if (el) {
    el.scrollTop = el.scrollHeight;
  }
};

export const removeWhiteSpaces = (value: string) => value.replace(/ /g, "");

export const statusesEN = {
  archived: "Archived",
  draft: "Draft",
  out_of_order: "Out of order",
  published: "Active",
};

export const statusesPL = {
  archived: "Zarchiwizowany",
  draft: "Szkic",
  out_of_order: "Niesprawny",
  published: "Sprawny",
};

export const getMachineProcessStatusTranslation = (
  language: string,
  status: MachineProcessStatus,
) => {
  const isEnglish = language.includes(CountryCodes.EN);
  const isPolish = language.includes(CountryCodes.PL);
  let result = "";

  if (isEnglish) {
    result = statusesEN[status];
  }

  if (isPolish) {
    result = statusesPL[status];
  }

  return result;
};

export const getInitial = (name: string) => (name ? name.charAt(0).toUpperCase() : "");

export const getCatchErrorMessage = (err: unknown) =>
  err instanceof Error ? err.message : String(err);

export const checkIfAnswerContainsTextReaction = (answer: Answer | undefined) =>
  !!answer?.ReactionValue?.length;

const { YES_NO_PHOTO, FILE_PHOTO } = QUESTION_TYPE_IDS;

export const checkIfPhotoType = ({
  QuestionType,
}: QuestionObject | AccidentFormQuestion | Answer) =>
  [YES_NO_PHOTO, FILE_PHOTO].some((type) => QuestionType === type);

export const isObjectEmpty = (object: null | undefined | Record<string, any>) => {
  if (!object) {
    return true;
  }

  return Object.keys(object).length === 0 && object.constructor === Object;
};

export const statusColors = {
  published: "#50C878",
  out_of_order: "#e0115f",
};

export const formatDate = (date: number | string | Date) =>
  format(new Date(date), "dd/MM/yyyy HH:mm");

const checkIfApk = () => {
  let isAPK = false;

  if (navigator.userAgent.includes("Android")) {
    if (navigator.userAgent.includes("wv") || navigator.userAgent.includes("WebView")) {
      isAPK = true;
    }
  }

  return isAPK;
};

export const isAPK = checkIfApk();

export const MAX_INT_VALUE = 2147483647;
export const DEVICE_INFO_STRING = "device-info-string";

export const convertToString = (value: string | Date | null) =>
  value ? format(new Date(value as Date), "dd/MM/yyyy") : "";

export const getTarget = () => {
  let target: HTMLAttributeAnchorTarget = "_blank";

  if (isMobile || isAPK) {
    target = "_self";
  }

  return target;
};
