import styled, { css } from "styled-components/macro";

import { ReactComponent as UncheckedIcon } from "assets/icons/checkbox-unchecked.svg";
import { ReactComponent as CheckedIcon } from "assets/icons/checkbox-checked.svg";

const Container = styled.div<{ archived: boolean; small: boolean }>`
  display: grid;
  place-content: center;

  ${({ archived }) =>
    archived &&
    css`
      opacity: 0.4;
    `}

  .checkbox-icon {
    width: ${({ small }) => (small ? "2rem" : "2.7rem")};
    height: ${({ small }) => (small ? "2rem" : "2.7rem")};
  }
`;

function CheckboxIcon({
  checked,
  archived = false,
  small = false,
}: {
  checked: boolean;
  archived?: boolean;
  small?: boolean;
}) {
  const icon = checked ? (
    <CheckedIcon className='checkbox-icon' />
  ) : (
    <UncheckedIcon className='checkbox-icon' />
  );

  return (
    <Container archived={archived} small={small}>
      {icon}
    </Container>
  );
}

export default CheckboxIcon;
