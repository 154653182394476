import styled, { css } from "styled-components/macro";

import { device } from "utils/utils";

export const Container = styled.div<{ containerHeight: number }>`
  min-height: ${({ containerHeight }) => `${(containerHeight - 81) / 10}rem`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const Content = styled.div``;

export const Header = styled.div`
  position: relative;
  padding: 1rem 0;
  text-align: center;

  .arrow-back {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 2.5rem;
      path {
        fill: ${({ theme }) => theme.textColor1};
      }
    }
  }
`;

export const HeaderTitle = styled.span`
  font-size: 1.8rem;

  @media ${device.iPad} {
    font-size: 2.2rem;
  }
`;

export const DepartmentsList = styled.ul`
  margin-top: 1rem;
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const DepartmentsListItem = styled.li<{ isMobile: boolean }>`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};

  @media ${device.iPad} {
    font-size: 1.8rem;
    padding: 1rem;
  }

  &:nth-child(odd) {
    background-color: rgba(61, 91, 148, 0.5);
  }

  &:nth-child(even) {
    background-color: rgba(43, 64, 114, 0.5);
  }
`;

export const DeptNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${({ theme }) => theme.ellipsis}
`;

export const DeptName = styled.span<{ archived: boolean }>`
  padding-right: 0.6rem;
  overflow: hidden;
  ${({ theme }) => theme.ellipsis};

  ${({ archived }) =>
    archived &&
    css`
      opacity: 0.4;
    `}
`;

export const Actions = styled.div<{ spaceBetween: boolean }>`
  display: flex;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "flex-end")};
  padding-bottom: 0.5rem;
  padding-top: 1.5rem;
`;

export const ButtonContainer = styled.div`
  width: 10rem;

  .save-btn {
    &:disabled {
      background-color: rgba(61, 91, 148, 1);
      opacity: 0.4;
    }
  }
`;

export const ArchivedSwitchContainer = styled.div`
  height: 5rem;
  width: calc(100% - 10rem);
  display: flex;
  align-items: center;
`;

export const ArchivedSwitch = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.9;
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
`;

export const ArchivedLabel = styled.span`
  user-select: none;
  font-size: 1.25rem;
`;
