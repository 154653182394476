import { ChangeEvent, KeyboardEvent, forwardRef, FocusEvent } from "react";
import styled, { css } from "styled-components/macro";
import { ErrorPosition } from "types/types";

type InputProps = {
  type?: string;
  value?: string | number;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  largeFont?: boolean;
  error?: string;
  disabled?: boolean;
  name?: string;
  paddingRight?: boolean;
  small?: boolean;
  maxLength?: number;
  large?: boolean;
  noMargin?: boolean;
  noLabel?: boolean;
  errorPosition?: ErrorPosition;
  alignRight?: boolean;
  customClass?: string;
};

const { BOTTOM_LEFT } = ErrorPosition;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  position: relative;
`;

const StyledInput = styled.input<{
  type?: string;
  largeFont?: boolean;
  disabled?: boolean;
  isError: boolean;
  paddingRight?: boolean;
  small?: boolean;
  large?: boolean;
  noMargin?: boolean;
  noLabel?: boolean;
  alignRight?: boolean;
}>`
  color: ${({ disabled, theme }) => (disabled ? theme.greyLight : theme.textColor2)};
  width: 100%;
  background-color: ${({ theme, disabled }) =>
    !disabled ? "#fff" : theme.disabledColor};
  outline: none;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "0.5rem")};
  border: none;
  border-radius: 0.4rem;
  padding: 0.5rem;
  height: 4rem;
  padding-right: ${({ paddingRight }) => (paddingRight ? "4rem" : "0")};

  @media screen and (min-height: 508px) {
    height: 5rem;
    font-size: 2.2rem;
  }

  ::placeholder {
    font-size: 2.2rem;
    opacity: 0.6;
    padding-left: 0.5rem;
  }

  &:disabled {
    ::placeholder {
      color: hsla(0, 0%, 100%, 0.2);
    }
  }

  &[type="datetime-local"] {
    font-size: 1.6rem;
    padding-left: 1.5rem;
  }

  ${({ largeFont }) =>
    largeFont &&
    css`
      font-size: 2.2rem;
      letter-spacing: 1px;

      ::placeholder {
        font-size: 1.8rem;
      }
    `}

  &:focus {
    outline: 0;
    border-color: ${({ theme }) => theme.primary_300};
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

    ${({ isError }) =>
      isError &&
      css`
        box-shadow: none;
        border-color: ${({ theme }) => theme.errorColor};
      `}
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 2px solid ${({ theme }) => theme.errorColor};
    `}

  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
      padding-right: 1rem;
    `}
`;

const Label = styled.label<{ label?: string }>`
  font-size: 1.4rem;
  margin-bottom: ${({ label }) => (!!label ? "0.5rem" : "0")};

  @media screen and (min-height: 616px) {
    font-size: ${({ theme }) => theme.labelFonstSize};
  }
`;

const Error = styled.span<{
  small?: boolean;
  noLabel?: boolean;
  errorPosition?: ErrorPosition;
}>`
  color: ${({ theme }) => theme.errorColor};
  font-size: 1.4rem;
  position: absolute;
  right: 0.2rem;
  bottom: -1rem;
  z-index: ${({ theme }) => theme.level1};

  ${({ errorPosition }) =>
    errorPosition === BOTTOM_LEFT &&
    css`
      right: 0;
      left: 0.2rem;
    `}

  @media screen and (min-height: 508px) {
    bottom: -1.1rem;
  }

  @media screen and (min-height: 616px) {
    bottom: -1.6rem;
  }
`;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      value,
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      onKeyUp,
      placeholder,
      label = "",
      readOnly,
      min,
      step,
      max,
      largeFont = true,
      error = "",
      errorPosition = undefined,
      disabled = false,
      name = "",
      paddingRight = false,
      small = false,
      maxLength,
      large = true,
      noMargin = false,
      noLabel = false,
      alignRight = false,
      customClass = "",
    },
    ref,
  ) => {
    return (
      <Wrapper>
        <Label label={label}>{label}</Label>

        <InputContainer>
          <StyledInput
            ref={ref}
            disabled={disabled}
            isError={!!error}
            largeFont={largeFont}
            max={max}
            maxLength={maxLength}
            min={min}
            name={name}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            paddingRight={paddingRight}
            placeholder={placeholder}
            readOnly={readOnly}
            small={small}
            step={step}
            type={type}
            value={value}
            large={large}
            noMargin={noMargin}
            autoComplete='off'
            alignRight={alignRight}
            className={customClass}
          />
          {error && (
            <Error small={small} noLabel={noLabel} errorPosition={errorPosition}>
              {error}
            </Error>
          )}
        </InputContainer>
      </Wrapper>
    );
  },
);

export default Input;
