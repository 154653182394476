import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  QUESTION_TYPE_IDS,
  Answers,
  Answer,
  FormType,
  ErrorPosition,
  QuestionObject,
} from "types/types";
import useTimeout from "hooks/useTimeout";
import useScreen from "hooks/useScreen";
import useModal from "hooks/useModal";
import useAnimate from "hooks/useAnimate";
import usePhotosObjectStore from "hooks/usePhotosObjectStore";
import useDictaphoneAccess from "hooks/useDictaphoneAccess";
import {
  device,
  isMobile,
  checkIfAnswerContainsTextReaction,
  MAX_INT_VALUE,
  scrollToBottom,
} from "utils/utils";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import ButtonLabel from "./components/button-label/ButtonLabel";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import Dictaphone from "../Dictaphone";

import { TextArea } from "styles/generalStyles";
import { getTranslations } from "./translations/question-actions.translations";
import AnswerSelect from "./components/answer-select/AnswerSelect";

const Container = styled.div<{
  isFocused: boolean;
  isMobile: boolean;
  isHeightSet: boolean;
}>`
  width: 100%;
  padding-bottom: 1rem;

  ${({ isHeightSet }) =>
    isHeightSet &&
    css`
      height: 100%;
    `}

  display: ${({ isMobile, isFocused }) =>
    !isMobile ? "grid" : isMobile && isFocused ? "block" : "grid"};

  @media ${device.iPad} {
    display: ${({ isFocused }) => (isFocused ? "block" : "grid")};
  }
`;

const InputWrapper = styled.div<{ animate: boolean; duration: string }>`
  ${({ animate }) =>
    animate &&
    css<{ duration: string }>`
      animation-name: fade-in;
      animation-duration: ${({ duration }) => duration};
      animation-timing-function: ease-in;

      @keyframes fade-in {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `}
`;

const Actions = styled.div<{ spaceBetween?: boolean; isNA?: boolean }>`
  display: flex;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? "space-between" : "flex-end")};
  align-items: center;
  padding-top: 0.7rem;
  width: 100%;
  place-self: end;
  position: relative;

  .enlarged {
    ${({ isNA }) =>
      isNA
        ? css`
            position: absolute;
            bottom: 0;
            width: 100%;
          `
        : css`
            flex-basis: 100%;
          `}
  }

  .hidden {
    display: none;
  }
`;

const NextBtnContainer = styled.div<{ smallView: boolean }>`
  width: ${({ smallView }) => (smallView ? "12rem" : "14rem")};
`;

const BtnContainer = styled.div<{ isNA: boolean }>`
  position: relative;
  flex-basis: ${({ isNA }) => (isNA ? "32%" : "48%")};
`;

const ResetQuestion = styled.div<{ yes?: boolean; no?: boolean; isMobile: boolean }>`
  position: absolute;
  top: -1.5rem;
  right: -1.1rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.cancelColor};
  z-index: ${({ theme }) => theme.level10};
  ${({ theme }) => theme.buttonShadow};
  ${({ theme }) => theme.buttonShadow};
  cursor: ${({ isMobile }) => (isMobile ? "default" : "pointer")};
  transition: all 0.2s ease-in-out;

  ${({ yes }) =>
    yes &&
    css`
      background-color: ${({ theme }) => theme.yesButton};
    `}

  ${({ no }) =>
    no &&
    css`
      background-color: ${({ theme }) => theme.noButton};
    `}

  .close-icon {
    fill: ${({ theme }) => theme.white};
  }

  &:hover {
    filter: brightness(95%);
  }
`;

type QuestionActionsProps = {
  formType: FormType;
  currentQuestion: QuestionObject;
  onYesClick: () => void;
  onNoClick: () => void;
  onNaClick: () => void;
  onNextClick: () => void;
  onTextTranscriptChange: (value: string) => void;
  onAnswerInputChange: (value: string) => void;
  answerInputValue: string;
  onAnswerTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  answerTextAreaValue: string;
  onAnswerSelectChange: (value: string) => void;
  answerSelectValue: { label: string; value: string };
  yesNoAnswer: string;
  answerToCurrentQuestion: Answer | undefined;
  removeAnswer: (Question: string) => Promise<void>;
};

const { FILE_PHOTO, NUMERIC, TEXT, YES_NO_PHOTO, YES_NO_TEXT, SELECT } =
  QUESTION_TYPE_IDS;
const { YES, NO, NA } = Answers;
const { BOTTOM_LEFT } = ErrorPosition;

function QuestionActions({
  formType,
  currentQuestion,
  onYesClick,
  onNoClick,
  onNaClick,
  onNextClick,
  onTextTranscriptChange,
  onAnswerInputChange,
  answerInputValue = "",
  onAnswerTextAreaChange,
  answerTextAreaValue = "",
  onAnswerSelectChange,
  answerSelectValue,
  yesNoAnswer,
  answerToCurrentQuestion,
  removeAnswer,
}: QuestionActionsProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [numberInputError, setNumberInputError] = useState({ message: "" });
  const { open, openModal, closeModal } = useModal();
  const { isSmallScreen, smallView } = useScreen();
  const {
    t,
    i18n: { language },
  } = useTranslation("common");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { error, modal } = getTranslations(language);

  const { checkIfContainsPhotos } = usePhotosObjectStore(formType);
  const { isDictaphoneAccessible } = useDictaphoneAccess();
  const { animate, duration } = useAnimate();

  const setTimeOut = useTimeout();

  const [searchParams] = useSearchParams();
  const isListening = !!searchParams.get("listening");

  const { AllowNA, QuestionType, Options } = currentQuestion;

  const rows = isSmallScreen ? 3 : 5;
  const Question = answerToCurrentQuestion?.Question ?? "";

  let content;

  // --------------- Effect handlers ---------------

  useEffect(() => {
    if (!textareaRef.current) return;

    const { current: textArea } = textareaRef;

    scrollToBottom(textArea);
  }, [answerTextAreaValue]);

  // --------------- On click handlers ---------------

  const handleYesClick = async () => {
    switch (QuestionType) {
      case YES_NO_PHOTO:
        const containsPhotos = await checkIfContainsPhotos(Question);

        if (containsPhotos) {
          openModal();
        } else {
          onYesClick();
        }

        break;
      case YES_NO_TEXT:
        if (checkIfAnswerContainsTextReaction(answerToCurrentQuestion)) {
          openModal();
        } else {
          onYesClick();
        }
        break;
      default:
        onYesClick();
    }
  };

  const onConfirmationClick = () => {
    removeAnswer(Question);
    closeModal();
  };

  const onResetQuestionClick = () => {
    openModal();
  };

  // --------------- Event handlers ---------------

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setTimeOut(() => {
      setIsFocused(false);
    }, 100);
  };

  const onNumberInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(e.target.value);

    if (isNaN(inputValue)) return;

    const answerValue =
      inputValue !== Infinity ? `${Math.abs(Math.trunc(inputValue))}` : answerInputValue;

    onAnswerInputChange(answerValue);

    if (inputValue > MAX_INT_VALUE) {
      setNumberInputError({ message: error.number_too_large });
    } else {
      if (numberInputError.message) {
        setNumberInputError({ message: "" });
      }
    }
  };

  // --------------- Content ---------------

  switch (QuestionType) {
    case FILE_PHOTO:
      content = (
        <Actions>
          <NextBtnContainer smallView={smallView}>
            <Button label={t("button.next")} large question onClick={onNextClick} />
          </NextBtnContainer>
        </Actions>
      );
      break;

    case NUMERIC:
      content = (
        <>
          <InputWrapper animate={animate} duration={`${duration}ms`}>
            <Input
              large
              onChange={onNumberInputChange}
              value={answerInputValue}
              type='tel'
              onFocus={onFocus}
              onBlur={onBlur}
              error={numberInputError.message}
              name='number-input'
              errorPosition={isMobile ? BOTTOM_LEFT : undefined}
              alignRight
            />
          </InputWrapper>
          <Actions>
            <NextBtnContainer smallView={smallView}>
              <Button
                label={t("button.next")}
                large
                question
                onClick={onNextClick}
                disabled={!!numberInputError.message}
              />
            </NextBtnContainer>
          </Actions>
        </>
      );
      break;

    case TEXT:
      content = (
        <>
          <TextArea
            rows={rows}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onAnswerTextAreaChange}
            value={answerTextAreaValue}
            ref={textareaRef}
          />
          <Actions spaceBetween={isDictaphoneAccessible}>
            {isDictaphoneAccessible && (
              <Dictaphone
                onTextTranscriptChange={onTextTranscriptChange}
                questionType={QuestionType}
                answerTextAreaValue={answerTextAreaValue}
              />
            )}
            <NextBtnContainer smallView={smallView}>
              <Button
                label={t("button.next")}
                large
                question
                onClick={onNextClick}
                disabled={isListening}
              />
            </NextBtnContainer>
          </Actions>
        </>
      );
      break;

    case SELECT:
      content = (
        <>
          <AnswerSelect
            options={Options}
            onAnswerSelectChange={onAnswerSelectChange}
            answerSelectValue={answerSelectValue}
          />

          <Actions>
            <NextBtnContainer smallView={smallView}>
              <Button
                label={t("button.next")}
                large
                question
                onClick={onNextClick}
                // disabled={!!numberInputError.message}
              />
            </NextBtnContainer>
          </Actions>
        </>
      );
      break;

    default:
      content = (
        <Actions spaceBetween isNA={!!AllowNA}>
          {/* NO BUTTON */}
          <BtnContainer
            isNA={!!AllowNA}
            className={
              yesNoAnswer === NO
                ? "enlarged"
                : yesNoAnswer === YES || yesNoAnswer === NA
                ? "hidden"
                : ""
            }
          >
            <Button
              label={<ButtonLabel selected={yesNoAnswer === NO} down />}
              large
              noButton
              onClick={onNoClick}
              answered={yesNoAnswer === NO}
            />

            {yesNoAnswer === NO && (
              <ResetQuestion isMobile={isMobile} no onClick={onResetQuestionClick}>
                <CloseIcon className='close-icon' />
              </ResetQuestion>
            )}
          </BtnContainer>

          {/* NA BUTTON */}
          {AllowNA && (
            <BtnContainer
              isNA
              className={
                yesNoAnswer === NA
                  ? "enlarged"
                  : yesNoAnswer === NO || yesNoAnswer === YES
                  ? "hidden"
                  : ""
              }
            >
              <Button
                label={<ButtonLabel selected={yesNoAnswer === NA} na />}
                large
                naButton
                onClick={onNaClick}
                answered={yesNoAnswer === NA}
              />

              {yesNoAnswer === NA && (
                <ResetQuestion isMobile={isMobile} onClick={onResetQuestionClick}>
                  <CloseIcon className='close-icon' />
                </ResetQuestion>
              )}
            </BtnContainer>
          )}

          {/* YES BUTTON */}
          <BtnContainer
            isNA={!!AllowNA}
            className={
              yesNoAnswer === YES
                ? "enlarged"
                : yesNoAnswer === NO || yesNoAnswer === NA
                ? "hidden"
                : ""
            }
          >
            <Button
              label={<ButtonLabel selected={yesNoAnswer === YES} up />}
              large
              yesButton
              onClick={handleYesClick}
              answered={yesNoAnswer === YES}
            />
            {yesNoAnswer === YES && (
              <ResetQuestion isMobile={isMobile} yes onClick={onResetQuestionClick}>
                <CloseIcon className='close-icon' />
              </ResetQuestion>
            )}
          </BtnContainer>
        </Actions>
      );
  }

  return (
    <Container
      isFocused={isFocused}
      isMobile={isMobile}
      isHeightSet={
        QuestionType === TEXT || QuestionType === NUMERIC || QuestionType === SELECT
      }
    >
      {content}

      <ConfirmationModal
        message={modal.message}
        onClick={onConfirmationClick}
        onClose={closeModal}
        open={open}
        buttonLabel={modal.btnLabel}
      />
    </Container>
  );
}

export default QuestionActions;
